import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
const BASE_URL = environment.BASE_URL;
const BASE_URL_MANAGER = environment.BASE_URL_MANAGER;
@Injectable({
    providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
    ) { }

  apiGet(paramsQuery): Observable<any[]> {
    return this.http.get<any[]>(`${BASE_URL}/api/v2/employee/GetEmployeeList?` + paramsQuery);
  }

  apiPut(params): Observable<any> {
    return this.http.put<any>(`${BASE_URL}/api/v1/customer/SetMyCustProfile`, params);
  }

  apiPost(params): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/api/v1/coreuser/SetProfileMeta`, params);
  }

  apiDelete(metaId): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/api/v1/coreuser/DelProfileMeta?metaId=${metaId}`);
  }

  getUsersByCust(paramsQuery): Observable<any[]> {
      return this.http.get<any>(`${BASE_URL}/api/v1/user/GetUsersByCust?${paramsQuery}`);
  }
  
  getEmployeeList(paramsQuery): Observable<any[]> {
      return this.http.get<any>(`${BASE_URL}/api/v2/employee/GetEmployeeList?${paramsQuery}`);
  }
  
  sendOTP(paramsQuery): Observable<any> {
      return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/SendOTP`, paramsQuery);
  }

  sendOTPEmail(paramsQuery): Observable<any> {
    return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/SendMailOtp`, paramsQuery);
}

  addCustomer(paramsQuery): Observable<any[]> {
    return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/Add`, paramsQuery);
  }

  addCustomerEmail(paramsQuery): Observable<any[]> {
    return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/AddWithMail`, paramsQuery);
  }
  
  getRootUserInfoByStoreName(storeName):Observable<any>{
    return this.http.get<any>(`${BASE_URL_MANAGER}/api/v1/customer/GetCustomerByStoreName?store=${storeName}`);
  }

  resetPassword(paramsQuery): Observable<any> {
    return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/ResetPassword`, paramsQuery);
  }

  verifyOtp(request) : Observable<any>{
    return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/verifyOtp`, request);
  }

  // verifyOtpEmail(request) : Observable<any>{
  //   return this.http.post<any>(`${BASE_URL_MANAGER}/api/v1/customer/VerifyEmailOtp`, request);
  // }
  
  getcities(): Observable<any[]> {
    return this.http.get<any>(`${BASE_URL_MANAGER}/api/v1/customer/GetCities/cities`);
  }

  getPagingPackage(paramsQuery): Observable<any[]> {
    return this.http.get<any>(`${BASE_URL_MANAGER}/api/v1/package/GetAll?` + paramsQuery);
  }


  //apiExport localhost
  exportReportLocalhost(url): Observable<Blob> {
    return this.http.get(url, {
      responseType: "blob"
    });
  }
}
