import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private _service: ApiService,
    private _message: MessageService,
    private _route  : Router
  ) { }
  step = 'FindStore';
  resetPassword = {
    store_name: null,
    password: null,
    rePassword: null,
    otp: null,
    phone: null,
    email: ''
  }
  counter = 30;
  isSent = false;
  status = {
    showPassword: false,
    showConfirmPassword: false
  }


  ngOnInit(): void {
  }

  validatePhoneNumber(phoneNumber) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phoneNumber);
  }
  sendOtp() {
    if (this.resetPassword.store_name == '' || this.resetPassword.store_name == undefined) {
      this._message.add({ severity: 'error', summary: 'Thông báo', detail: 'Vui lòng điền đẩy đủ thông tin.' });
      return;
    }

    this._service.getRootUserInfoByStoreName(this.resetPassword.store_name).subscribe(
      response => {
        console.log(response);
        if (response.statusCode != 1) {
          this._message.add({ severity: 'error', summary: 'Thông báo', detail: response.error[0] });
          return;
        }
        // this.resetPassword.store_name = response.data.store_name;
        this.resetPassword.phone = response.data.phone;
        let request = {
          phone: response.data.phone,
          otp: '',
          email : response.data.email || ''
        };
        this._service.sendOTP(request).subscribe(
          response => {
            this.step = "VerifyOtp";
            this._message.add({ severity: 'success', summary: 'Thông báo', detail: response.message });
            this.isSent = true;
            this.startCountdown();
          }
        )
      }
    )
  }

  verifyOtp() {
    if (this.isSent) {
      let request = {
        phone: this.resetPassword.phone,
        otp: this.resetPassword.otp,
        email : this.resetPassword.email || ''
      }
      this._service.verifyOtp(request).subscribe(
        response => {
          if (response.statusCode == 1) {
            this.step = "ChangePassword";
            return;
          }
          this._message.add({ severity: 'error', summary: 'Thông báo', detail: response.error[0] });
        }
      )
    }
  }

  ChangePassword() {
    var re = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    if(!this.resetPassword.password.match(re)){
      this._message.add({ severity: 'error', summary: 'Thông báo', detail: 'Mật khẩu bao gồm chữ và số, ít nhất 8 ký tự' });
      return;
    }
    if (this.resetPassword.password == '' || this.resetPassword.password == null) {
      this._message.add({ severity: 'error', summary: 'Thông báo', detail: 'Vui lòng điền đẩy đủ thông tin.' });
      return;
    }

    if (this.resetPassword.password !== this.resetPassword.rePassword) {
      this._message.add({ severity: 'error', summary: 'Thông báo', detail: 'Mật khẩu không khớp' });
      return;
    }
    let request = {
      store_name : this.resetPassword.store_name,
      password : this.resetPassword.password,
      otp: this.resetPassword.otp
    }

    this._service.resetPassword(request).subscribe(
        response => {
          if (response.statusCode == 1) {
            this.step = "Done";
            return;
          }
          this._message.add({ severity: 'error', summary: 'Thông báo', detail: response.error[0] });
        }
      )
  }

  goLogin(){
    this._route.navigate(['login']);
  }

  startCountdown() {
    this.counter = 60;
    const interval = setInterval(() => {
      this.counter--;
      if (this.counter == 0) {
        this.isSent = false;
        clearInterval(interval);
      }
    }, 1000)
  }

}
