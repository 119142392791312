export const KEYBUTTON = {
    'XEMCHITIET': 'view',
    'THEMMOI': 'add',
    'XOATHONGTIN': 'remove',
}

export const ACTIONS = {
    'VIEW': 'ACTION_VIEW',
    'ADD': 'ACTION_ADD',
    'EDIT': 'ACTION_EDIT',
    'DELETE': 'ACTION_DELETE',
    'DELETE_ALL': 'Action_Delete_All',
    'TIM_KIEM_THEO_KEY': 'TIM_KIEM_THEO_KEY',
    'IN_TEEM': 'IN_TEEM',
    'XUAT_PLU': 'XUAT_PLU',
    'CAP_NHAT': 'CAP_NHAT',
    'THAY_DOI_TRANG_THAI': 'THAY_DOI_TRANG_THAI',
    'LOC_TIM_KIEM': 'LOC_TIM_KIEM',
    'TINH_GIA': 'TINH_GIA',
    'ERROR_DANH_SACH_TON_KHO': 'ERROR_DANH_SACH_TON_KHO',
    'IMPORT': 'IMPORT',
    'THEM_NHIỀU_SAN_PHAM': 'THEM_NHIỀU_SAN_PHAM',
    'TAI_FILE': 'TAI_FILE',
    'IN': 'IN',
    'COPY': 'COPY',
    'THEM_HOA_DON': 'THEM_HOA_DON',
    'TIM_KIEM_PHIEU': 'TIM_KIEM_PHIEU',
    'CHON_DON_HANG': 'CHON_DON_HANG',
    'KHOA_PHIEU': 'KHOA_PHIEU',
    'Undo': 'Undo',
    'XEM_SAN_PHAM_NCC': 'XEM_SAN_PHAM_NCC',
    'CHI_TIET_NCC': 'CHI_TIET_NCC',
    'PHU_LUC_CS_NCC': 'PHU_LUC_CS_NCC',
    'XUAT_FILE': 'XUAT_FILE',
    'TRA_DON_HANG': 'TRA_DON_HANG',
    'TAI_DON_HANG': 'TAI_DON_HANG',
    'CHUYEN_NCC': 'CHUYEN_NCC',
    'CHUYEN_PHE_DUYET': 'CHUYEN_PHE_DUYET',
    'CHUYEN_LAI_PHE_DUYET': 'CHUYEN_LAI_PHE_DUYET',
    'THEM_PHU_LUC': 'THEM_PHU_LUC',
    'CAP_VOUCHER': 'CAP_VOUCHER',
    'KHOA_VOUCHER': 'KHOA_VOUCHER',
    'MO_KHOA_VOUCHER': 'MO_KHOA_VOUCHER',
    'GIOI_HAN_VOUCHER': 'GIOI_HAN_VOUCHER',
    'KIEM_KE': 'KIEM_KE',
    'CHI_TIET_KIEM_KE': 'CHI_TIET_KIEM_KE',
    'TAO_PHE_DUYET': 'TAO_PHE_DUYET',
    'PHE_DUYET': 'PHE_DUYET',
    'XEM_CHI_TIET_DS_NCC': 'QUAN_TRI_HANG_HOA/DS_NHA_CUNG_CAP/VIEW',
    'SUA_CHI_TIET_DS_NCC': 'QUAN_TRI_HANG_HOA/DS_NHA_CUNG_CAP/EDIT',
    'QUY_DOI_DV_TINH_NCC': 'QUAN_TRI_HANG_HOA/DS_NHA_CUNG_CAP/DON_VI_TINH',
    'HUY_KHOA': 'HUY_KHOA',
    'KHOA': 'KHOA',
    'UNDO': 'UNDO',
}


export const MessageErrorAction = {
    ERROR_QUY_DOI_DV_TINH_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_SUA_CHI_TIET_DS_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_XEM_CHI_TIET_DS_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_UNDO: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_HUY_KHOA: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_KHOA: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TAO_PHE_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PHE_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CHI_TIET_KIEM_KE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_KIEM_KE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_GIOI_HAN_VOUCHER: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_MO_KHOA_VOUCHER: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_KHOA_VOUCHER: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CAP_VOUCHER: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_THEM_PHU_LUC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CHUYEN_LAI_PHE_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CHUYEN_PHE_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CHUYEN_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TAI_DON_HANG: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TRA_DON_HANG: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_XUAT_FILE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PHU_LUC_CS_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CHI_TIET_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_XEM_SAN_PHAM_NCC: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_Undo: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_KHOA_PHIEU: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CHON_DON_HANG: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TIM_KIEM_PHIEU: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_THEM_HOA_DON: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_COPY: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_IN: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_VIEW: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ADD: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_DELETE_ALL: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TIM_KIEM_THEO_KEY: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_IN_TEEM: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_XUAT_PLU: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_CAP_NHAT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_THAY_DOI_TRANG_THAI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_LOC_TIM_KIEM: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TINH_GIA: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_DANH_SACH_TON_KHO: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_IMPORT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_THEM_NHIỀU_SAN_PHAM: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_TAI_FILE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PAYBOX_NOTFOUND: 'Không lấy được thông tin Paybox',
    ERROR_PAYBOX_LINKFAIL: 'Liên kết tài khoản Paybox không thành công'


}




export const MENUACTIONROLEAPI = {
    'GetUnitPage': {
        'name': 'Danh sách đơn vị tính',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/catalog/unit-page'
    },
    'GetStoresPage': {
        'name': 'Danh sách siêu thị',
        'api': 'e31c63c5-1b71-493d-8132-f9e72a41762a',
        'menu': '',
        'url': '/catalog/store-by-user'
    },
    'GetProductCategoryPage': {
        'name': 'Danh sách nhóm hàng hóa - siêu thị',
        'api': 'f4ef5cd6-2a80-4cff-bc77-6264ade66d29',
        'menu': '',
        'url': '/catalog/store-product-category'
    },
    'GetDenominationPage': {
        'name': 'Danh sách mệnh giá tiền tệ',
        'api': 'fff8cc75-cfd0-4362-9aa6-e75918822943',
        'menu': '',
        'url': '/catalog/denomination-page'
    },
    'GetCountryPage': {
        'name': 'Danh sách quốc gia',
        'api': 'ed9ca876-348b-4354-9b8a-d7ee6a24eef8',
        'menu': '',
        'url': '/catalog/country-page'
    },
    'GetPromotionPage': {
        'name': 'Chính sách hàng hóa - Siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/catalog/store-promotion-page'
    },
    'GetPromotionOnBillTotalPage': {
        'name': 'Chính sách bán hàng trên giá trị hóa đơn - Siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/catalog/store_promotion_total_on_bill'
    },
    'GetPromotionOnBillPage': {
        'name': 'Chính sách bán hàng trên giá trị hóa đơn - Siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/catalog/store_promotion_on_bill'
    },
    'GetProductPage': {
        'name': 'Danh sách hàng hóa - Siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/product/store-product-page'
    },
    'GetInventoryPage': {
        'name': 'Danh sách Kiểm kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/product/inventory'
    },
    'GetProductBundlePage': {
        'name': 'Danh sách cấu hình tách gộp mã',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/product/configuration-castcode'
    },
    'GetCurrentStockPage': {
        'name': 'Danh sách hàng tồn kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/product/inventory-management'
    },
    'GetOrdersPageST': {
        'name': 'Danh sách đơn đặt hàng cần phê duyệt-siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/exchange/order-page'
    },
    'GetOrdersPage1ST': {
        'name': 'Danh sách đơn đặt hàng gửi trực tiếp-siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/exchange/order-emergency-page'
    },
    'GetStockDiaryPageHDST': {
        'name': 'Danh sách Hóa đơn',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/exchange/bill-page'
    },
    'GetStockDiaryPageNKST': {
        'name': 'Danh sách Nhập kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/exchange/purchase-order-page'
    },
    'GetStockDiaryPageXKST': {
        'name': 'Danh sách Xuất kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/exchange/export-cancel-product-page'
    },
    'GetSupplierPage': {
        'name': 'Danh sách đối tác nhà cung cấp',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/partner/store-supplier'
    },
    'GetCustomerPage': {
        'name': 'Danh sách khách hàng',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/partner/customer-page'
    },
    'GetUserPage': {
        'name': 'Danh sách nhân viên siêu thị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/user/store-user'
    },
    'GetOrdersPageQT': {
        'name': 'Danh sách đơn đặt hàng cần phê duyệt',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/order-page'
    },
    'GetOrdersPage1QT': {
        'name': 'Danh sách đơn đặt hàng gửi trực tiếp',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/quan-tri-don-hang-khan-cap'
    },
    'GetStockDiaryPageNKQT': {
        'name': 'Danh sách nhập kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/nhap-hang'
    },
    'GetStockDiaryPageXKQT': {
        'name': 'Danh sách quản trị xuất kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/xuat-kho'
    },
    'GetUserPageQT': {
        'name': 'Danh sách quản trị người dùng',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/user-page'
    },
    'GetMenuConfigInfo': {
        'name': 'Danh sách quản trị nhóm người dùng',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/user-role-page'
    },
    'GetProductPageQT': {
        'name': 'Danh sách quản trị hàng hóa',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/product-page'
    },
    'GetStoresPageQT': {
        'name': 'Danh sách siêu thị - Quản trị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/store-page'
    },
    'GetPromotionPageQT': {
        'name': 'Danh sách chính sách hàng hóa - Quản trị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/chinh-sach-hang-hoa'
    },
    'GetProductCategoryPageQT': {
        'name': 'Danh sách quản trị nhóm hàng hóa',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/product-category-page'
    },
    'GetSupplierPageQT': {
        'name': 'Danh sách nhà cung cấp - Quản trị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/supplier-page'
    },
    'getApprovalOrder': {
        'name': 'Danh sách quản trị phê duyệt',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/approval-page'
    },
    'GetPromotionOnBillTotalPageQT': {
        'name': 'Chính sách bán hàng trên giá trị hóa đơn - Quản trị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/promotion-total-on-bill'
    },
    'getInventoryApprovalPageQT': {
        'name': 'Quản trị phê duyệt kiểm kho',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/approval-inventory-page'
    }, 'GetPromotionOnBillPageQT': {
        'name': 'Danh sách chính sách hóa đơn - Quản trị',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/promotion_on_bill'
    },
    'GetSupplierPolicyPage': {
        'name': 'Danh sách quản trị phụ lục nhà cung cấp',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/supplier-policy-page'
    },
    'GetCustomerPageQT': {
        'name': 'Danh sách quản trị khách hàng',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/manage-customer'
    },
    'GetDetailsPage': {
        'name': 'Danh sách quản trị voucher',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/voucher'
    },
    'GetPricePlanPage': {
        'name': 'Danh sách kế hoạch giá',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/ke-hoach-gia'
    },
    'GetUserMailPage': {
        'name': 'Cài đặt Email',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/cai-dat-email'
    },
    'GetCustomerGroupPageQT': {
        'name': 'Quản trị nhóm khách hàng',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/manager/quan-tri-nhom-khach-hang'
    },
}

export const noRowElm = `
    <div class = "flex flex-column noRowElm">
        <img src = '../../../assets/images/common/no-rows.svg' alt = 'no item'/>
        <span>Không có dữ liệu</span>
    </div>
`

export const STATUS_CODE = {
    SUCCESS: 'success'
}

export const STATUS_MESSAGE = {
    ERROR : ''
}

export const MAX_SIZE = 100000000;
