import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-config-grid',
  templateUrl: './config-grid.component.html',
  styleUrls: ['./config-grid.component.scss']
})
export class ConfigGridComponent implements OnInit {
  @Input() columnDefs = [];

  @Output() handleChangeVisibleEvent = new EventEmitter();
  @Output() handleSaveEvent = new EventEmitter();
  @Output() handleCancelEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  handleChangeVisible(event, data): void {
    this.handleChangeVisibleEvent.emit(data);
  }

  handleChangeOrdinal(event): void {
  }

  handleSave(): void {
    console.log('thi:', this.columnDefs);    
    this.handleSaveEvent.emit(this.columnDefs);
  }

  handleCancel(): void {
    this.handleCancelEvent.emit();
  }
}
