import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { ApiSmartService } from 'src/app/services/api-smart/apismart.service';
import { AuthService } from 'src/app/services/auth.service';
import { Menus } from './sidebar-customer-routes.config';
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar-customer',
    templateUrl: './sidebar-customer.component.html',
})

export class SidebarCustomerComponent implements OnInit {
    @ViewChild('filterInput') filterElementRef: ElementRef;
    menuItems: any[] = [];
    window = window
    constructor(
        private router: Router,
        private authService: AuthService,
        private apiSmart: ApiSmartService,
    ) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            const pathname = window.location.pathname;
            if (this.authService.getIdToken()) {
                this.getListMenuByUserId(pathname)
            }
        });
    }

    ngOnInit() {
    }

    getListMenuByUserId(pathname) {
        this.menuItems =Menus
        this.parseObjectProperties(this.menuItems, pathname);
        this.menuItems = [...this.menuItems];
    }


    parseObjectProperties(obj: any[], pathname) {
        for (let k of obj) {
            k.label = k.title;
            if (k.path && k.class !== 'navigation-header') {
                k.routerLink = k.path
            }
            if (k.submenus && k.submenus.length > 0) {
                k.items = k.submenus.filter((d: any) => d.class && (d.class.indexOf("hidden") < 0));
            } else {
                k.items = []
            }
            if (k.routerLink) {
                // active menu con
                if (k.routerLink && pathname === k.routerLink) {
                    k.styleClass = k.class + ' active'
                    k.icon = ''
                } else {
                    k.styleClass = k.class + ' no-active';
                    k.icon = ''
                }
            } else {
                //active cha
                if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.class === 'navigation-header') {
                    k.styleClass = k.class + " parent_active"
                } else {
                    k.styleClass = k.class + " parent_no_active"
                }
            }

            if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
                this.parseObjectProperties(k.items, pathname);
            }
        }
    }


}
