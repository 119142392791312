import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { ApiSmartService } from 'src/app/services/api-smart/apismart.service';
const queryString = require('query-string');
@Component({
  selector: 'app-edit-select',
  templateUrl: './edit-select.component.html',
  styleUrls: ['./edit-select.component.scss']
})
export class EditSelectComponent implements ICellRendererAngularComp {
  constructor(private apiSmart: ApiSmartService) {
    // this.getSupplierPage();
  }
  public value: any;
  suppliers = [
  ]
  params;
  label: string;

  agInit(params: any): void {
    console.log(params)
    this.params = params;
    this.suppliers = params.select[0].suppliers;
    this.value = params.data.supplier_id
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onChange($event: any, idx: any) {
    if (this.params.select[idx].onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        index: this.params.rowIndex
        // ...something
      };
      this.params.select[idx].onClick(params);
    }
  }
  getSupplierPage() {
    if(this.suppliers.length === 0) {
      const queryParams = queryString.stringify({ offSet: 0, pageSize: 100000000 });
      this.apiSmart.getSupplierPage(queryParams).subscribe(results => {
        this.suppliers = results.data.dataList.data.map(res => {
          return {
            label: `${res.name} [${res.code}]`,
            value: res.id,
            ...res
          }
        });
      });
    }
  }



}
