<div class="grid config_wrapper">
    <div class="col-12">
        <div class="grid">
            <div class="col-6">Tên cột</div>
            <div class="col-3">Thứ tự</div>
            <div class="col-3 text-right">Ẩn</div>
            <ng-container *ngFor = 'let config of columnDefs'>
                <div class="col-6">
                    {{config?.headerName}}
                </div>
                <div class="col-3">
                    <p-inputNumber
                        inputId="integeronly"
                        name = "ordinal"
                        class="ordinal"
                        [(ngModel)]="config.ordinal"
                    ></p-inputNumber>
                </div>
                <div class="col-3 text-right">
                    <p-checkbox
                        *ngIf = "config.viewGrid"
                        [(ngModel)]="config.hide"
                        [binary]="true"
                        inputId="binary"
                        (onChange)="handleChangeVisible($event, config)"
                    ></p-checkbox>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col-12 text-right">
        <button pButton type="button" class="p-button-sm mr-2" icon="pi pi-times" (click)="handleCancel()" label="Hủy bỏ"></button>
        <button pButton type="button" class="p-button-sm" icon="pi pi-save" (click)="handleSave()" label="Lưu lại"></button>
    </div>
</div>

