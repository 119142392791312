import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoginModel } from './../../models/login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,) {

  }
  timeDown;
  timeDownInterval;
  submitted = false;
  disableLogin = false;
  isLoginFail = '';
  loading = false;
  isShowPass = false
  loginForm: FormGroup = new FormGroup({
    username: new FormControl('root', [Validators.required]),
    password: new FormControl('123456', [Validators.required])
  });
  isShowForgotPassword = false;
  lutaikhoan = false;
  get f(): any { return this.loginForm.controls; }
  listItem = [];
  isShowExtendLicense = false;
  ngOnInit(): void {
    this.getItem();
    if (!localStorage.getItem('checkLogin') ){
      localStorage.setItem('checkLogin', '5');
      clearInterval(this.timeDownInterval)
    }
    localStorage.getItem('checkLogin') == '0' ? this.disableLogin = true : this.disableLogin = false;
    this.checkTimeDown();
    if (this.authService.getToken()) {
      this.router.navigate(['/home']);
      return
    }
    this.setUserPassword();
  }

  getItem() {
    this.listItem = [
      'Quản lý tồn kho',
      'Quản lý doanh thu',
      'Quản lý nhân viên',
      'Quản lý giao dịch',
      'Quản lý khách hàng',
    ]
  }

  checkTimeDown() {
    let isLoginFail = localStorage.getItem('time');
    this.timeDownInterval = setInterval(() => {
      const today = new Date(isLoginFail);
      const endDate = new Date();
      const minutes = Math.round(+(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60));
      const seconds = Math.round(+(Math.abs(endDate.getTime() - today.getTime()) / (1000) % 60));
      this.timeDown = `Thời gian khóa còn ${14 - minutes} phút ${60 - seconds} giây`;
      if (15 - minutes < 0) {
        localStorage.setItem('checkLogin', '5');
        this.disableLogin = false;
        clearInterval(this.timeDownInterval)
      }
    }, 1000);
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  handleLogin(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const dataLogin: LoginModel = this.loginForm.value;
    this.spinner.show();
    this.authService.login(dataLogin)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe({
        next: response => {
          clearInterval(this.timeDownInterval);
          if (response.error) {
            localStorage.setItem('checkLogin', (+localStorage.getItem('checkLogin') - 1).toString())
            const checkLogin = localStorage.getItem('checkLogin');
            this.messageService.add({
              severity: 'error', summary: 'Thông báo',
              detail: checkLogin == '0' ? 'Tên đăng nhập hoặc mật khẩu không đúng. Vì sự an toàn của tài khoản, việc đăng nhập trên thiết bị này sẽ bị tạm khóa trong 15 phút nếu nhập sai thông tin quá 5 lần.'
                : response.error_description
            });
            if (checkLogin == '0') {
              this.disableLogin = true
              localStorage.setItem('time', new Date().toString())
              this.timeDown = '';
              this.checkTimeDown();
            }
          } else {
            if (response?.error_description?.includes('hết hạn')) {
              this.isShowExtendLicense = true;
            } else {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Đăng nhập thành công' });
              this.authService.loginSuccess(response);
              localStorage.setItem('expired_date', response.expired_date)
              this.router.navigateByUrl('/home');
            }
          }
        },
        error: err => {
          this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Đăng nhập thất bại!' });
          console.error(err);
        }
      });
  }

  resetLoginError(): void {
    this.isLoginFail = '';
  }
  setUserPassword() {
    this.route.queryParams.subscribe(
      parm => {
        this.loginForm.get('username').setValue(parm['username']);
        this.loginForm.get('password').setValue(parm['password']);
      }
    )
  }
}
