import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { ButtonRendererComponent } from '../utils/common/button-renderer.component';
import { NormalButtonRendererComponent } from '../utils/common/normal-button-renderer.component';
import { ButtonRendererComponent1 } from '../utils/common/button-renderer.component-1';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonRendererMutiComponent } from '../utils/common/button-renderermutibuttons.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MarginPipe } from './margin.pipe';
import { MarginRoundPipe } from './margin-round.pipe';
import { NumericEditor } from '../utils/common/numeric-editor.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { ToolbarModule } from 'primeng/toolbar';
import { AvatarFullComponent } from '../common/ag-component/avatarFull.component';
import { ButtonAgGridComponent } from '../common/ag-component/button-renderermutibuttons.component';
import { CustomTooltipComponent } from '../common/ag-component/customtooltip.component';
import { CurrencyFormatPipeModule } from '../common/pipe/currency-pipe.module';
import { EditSelectComponent } from '../common/ag-component/ag-select/edit-select/edit-select.component';
import { MegaMenuModule } from 'primeng/megamenu';
import { SidebarCustomerComponent } from './sidebar-customer/sidebar-customer.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { ModalFilterComponent } from './modal-filter/modal-filter.component';
import { ConfigGridComponent } from './config-grid/config-grid.component';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        SidebarCustomerComponent,
        ToggleFullscreenDirective,
        ButtonRendererComponent,
        NormalButtonRendererComponent,
        ButtonRendererComponent1,
        ButtonRendererMutiComponent,
        AvatarFullComponent,
        MarginPipe,
        MarginRoundPipe,
        NumericEditor,
        ButtonAgGridComponent,
        EditSelectComponent,
        SearchFilterComponent,
        ModalFilterComponent,
        ConfigGridComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        SplitButtonModule,
        MegaMenuModule,
        FormsModule,
        DropdownModule,
        CalendarModule,
        PaginatorModule,
        AvatarModule,
        MenubarModule,
        ToolbarModule,
        CurrencyFormatPipeModule,
        MenuModule,
        AgGridModule.withComponents([
            ButtonRendererComponent,
            CustomTooltipComponent,
            ButtonRendererMutiComponent,
            ButtonRendererComponent1,
            NumericEditor
        ]),
        TableModule,
        CheckboxModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        SidebarCustomerComponent,
        ToggleFullscreenDirective,
        ButtonRendererComponent,
        NormalButtonRendererComponent,
        ButtonRendererComponent1,
        AvatarFullComponent,
        ButtonRendererMutiComponent,
        MarginPipe,
        MarginRoundPipe,
        NumericEditor,
        ButtonAgGridComponent,
        EditSelectComponent,
        SearchFilterComponent,
        ModalFilterComponent,
        ConfigGridComponent

    ],
    entryComponents: [
    ]
})
export class SharedModule { }
