<p-toolbar>
  <div class="p-toolbar-group-left">
    <img src="../../../assets/images/logo-text.png" alt="logo">
  </div>
  <div class="p-toolbar-group-right profile">
    <!-- <div class="supermarket">
      <p-dropdown class="dd-market" [options]="[]" placeholder="Chọn siêu thị" optionLabel="name"></p-dropdown>
    </div> -->
    <div class="notification">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 21.75C13.1 21.75 14 20.85 14 19.75H10C10 20.85 10.9 21.75 12 21.75ZM18 15.75V10.75C18 7.68 16.37 5.11 13.5 4.43V3.75C13.5 2.92 12.83 2.25 12 2.25C11.17 2.25 10.5 2.92 10.5 3.75V4.43C7.64 5.11 6 7.67 6 10.75V15.75L4 17.75V18.75H20V17.75L18 15.75Z" fill="white"/>
      </svg>
    </div>
    <div class="card flex align-items-center" (click)="menu.toggle($event)">
      <p-avatar class="img-avatar" image="../../../assets/images/no-image2.jpg" severity="danger"
        [style]="{'z-index': 9999}" shape="square"></p-avatar>
      <span class="ml-12 mr-15 text-color name"> {{name}}</span>
      <p-menu #menu [popup]="true" [model]="items" styleClass="mt-2"></p-menu>
      <i class="pi pi-angle-down cursor-pointer text-color"></i>
    </div>
  </div>
</p-toolbar>