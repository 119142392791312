import { RouteInfo } from './sidebar.metadata';

export const Menus: RouteInfo[] = [
    {
        path: '', title: 'Tổng quan', icon: '', class: 'navigation-header',
        badge: '', badgeClass: '', isExternalLink: false, isNavHeader: true, submenu: []
    },
    {
        path: '/home', title: 'Thông tin khách hàng', icon: 'fa fa-home', class: 'nav-item',
        badge: '', badgeClass: '', isExternalLink: false, isNavHeader: false,
        submenu: []
    },
    {
        path: '', title: 'Chính sách khách hàng', icon: '', class: 'navigation-header',
        badge: '', badgeClass: '', isExternalLink: false, isNavHeader: true, submenu: []
    },
    {
        path: '/customerlist', title: 'Hỗ trợ', icon: 'fa fa-user-secret', class: 'nav-item',
        badge: '', badgeClass: '', isExternalLink: false, isNavHeader: false,
        submenu: []
    },
   
];
