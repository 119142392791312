<p-dropdown [options]="suppliers" [autoDisplayFirst]="false" [appendTo]="'body'"
    [(ngModel)]="value" name="value" [filter]="true" [showClear]="false" (onChange)="onChange($event, 0)">
<ng-template let-item pTemplate="status">
    <span *ngIf="value" style="vertical-align:middle; ">{{item.label}}</span>
    <span *ngIf="!value" style="vertical-align:middle; "></span>
</ng-template>
<ng-template let-car pTemplate="item">
    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
        <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
    </div>
</ng-template>
</p-dropdown>