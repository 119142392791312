import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss']
})
export class ModalFilterComponent implements OnInit {
  @Input() styleCss: any;
  @Output() closeModalEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  closeModalFilter() {
    this.closeModalEvent.emit(true);
  }

}
