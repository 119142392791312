import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Input('isShowModal') isShowModal: boolean;
  @Output() openModalEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  onHandleOpenModalFilter() {
    this.isShowModal = !this.isShowModal;
    this.openModalEvent.emit({ 'isShowModalFilter': this.isShowModal });
  }

}
