import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ButtonAgGridComponent } from '../ag-component/button-renderermutibuttons.component';
import { CustomTooltipComponent } from '../ag-component/customtooltip.component';
import { AllModules, ColumnVisibleEvent, Module } from '@ag-grid-enterprise/all-modules';
import { AvatarFullComponent } from '../ag-component/avatarFull.component';
import { ActivatedRoute } from '@angular/router';
import { NumericEditor } from 'src/app/utils/common/numeric-editor.component';
import { EditSelectComponent } from '../ag-component/ag-select/edit-select/edit-select.component';
import { noRowElm } from '../constants/constant';
@Component({
  selector: 'app-list-grid-angular',
  templateUrl: './list-grid-angular.component.html',
  styleUrls: ['./list-grid-angular.component.css'],
})
export class ListGridAngularComponent
  implements OnInit, OnChanges, AfterViewInit
{
  public modules: Module[] = AllModules;
  @Input() listsData: Array<any> = [];
  @Output() FnClick = new EventEmitter<any>();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() rowDoubleClicked = new EventEmitter<any>();
  @Output() cellDoubleClicked = new EventEmitter<any>();
  @Output() cellValueChanged = new EventEmitter<any>();
  @Output() callback = new EventEmitter<any>();
  @Input() columnDefs: Array<any> = [];
  @Input() rowSelection: string = 'single';
  @Input() frameworkComponents = {};
  @Input() detailCellRendererParams: any;
  @Output() onColumnVisibleEvent = new EventEmitter<ColumnVisibleEvent>();
  @Input() rowClassRules: any = {
    notcalculate: function (params) {
      return params.data.gift === true;
    },
    isParent: function (params) {
      return params.data.isParent;
    },
    errorRow: function (params) {
      return params.data.hasOwnProperty('isSuccess') && !params.data.isSuccess;
    },
  };
  @Input() noRowsTemplate: any = noRowElm;
  @Input() pinnedTopRowData: any[] = [];
  @Input() floatingFilter: boolean = false;
  @Input() buttons = [];
  @Input() hideSidebar : boolean = false;
  @Input() title: string = '';
  @Input() idGrid: string = 'myGrid';
  @Input() isScrollBottom: boolean = false;
  // autoHeight
  @Input() domLayout: string = '';
  @Input() height: number = 630;
  @Input() headerHeight: number = 50;
  @Input() heightRow: number = 40;
  @Input() groupDefaultExpanded: number = 0;
  @Input() pageSize: number = 500;
  @Input() detailRowHeight: number = 300;
  @Input() getContextMenuItems: any = null;
  @Input() excelStyles: any[] = [
    {
      id: 'stringType',
      dataType: 'string',
    },
    {
      id: 'dateTimeFormat',
      dataType: 'DateTime',
      numberFormat: { format: 'dd/mm/yyy h:mm:ss AM/PM' },
    },
    {
      id: 'dateFormat',
      dataType: 'DateTime',
      numberFormat: { format: 'dd/mm/yyy' },
    },
    {
      id: 'text-right',
      dataType: 'number',
      numberFormat: { format: '#,##0.000' },
    },
    {
      id: 'text-number',
      dataType: 'number',
      numberFormat: { format: '#,##0.000' },
    },
    {
      id: 'number-string',
      dataType: 'string',
      // numberFormat: { format: '00#,##0.00;(#,##0.00)' },
    },
  ];
  defaultColDef;
  sideBar: any;
  gridApi: any;
  getRowHeight: any;
  gridColumnApi: any;
  heightAuto = 0;
  tooltipShowDelay = 0;
  isRowMaster;
  titlePage = '';
  excelExportParams;
  csvExportParams;
  checkAutoSize: string = '';
  constructor(private activatedRoute: ActivatedRoute) {
    this.titlePage = this.activatedRoute.data['_value'].title;
    this.excelExportParams = {
      fileName: this.titlePage ? this.titlePage : 'export',
    };
    this.csvExportParams = {
      fileName: this.titlePage ? this.titlePage : 'export',
    };
    this.isRowMaster = (dataItem) => {
      if (dataItem) {
        if (dataItem.Details) {
          return dataItem && dataItem.Details
            ? dataItem.Details.length > 0
            : false;
        } else if (dataItem.orderDetails) {
          return dataItem && dataItem.orderDetails
            ? dataItem.orderDetails.length > 0
            : false;
        } else if (dataItem.details) {
          return dataItem && dataItem.details
            ? dataItem.details.length > 0
            : false;
        } else if (dataItem.submenus) {
          return dataItem && dataItem.submenus
            ? dataItem.submenus.length > 0
            : false;
        } else if (dataItem.stockDiaryDetails) {
          return true;
        } else if (dataItem.extraPromotions) {
          return dataItem && dataItem.extraPromotions
            ? dataItem.extraPromotions.length > 0
            : false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    this.defaultColDef = {
      tooltipComponent: 'customTooltip',
      resizable: true,
      suppressSorting: false,
      sortable: false,
      suppressSizeToFit: false,
      filter: '',
      rowHeight: 90,
      cellClass: [],
      tooltipComponentParams: { color: '#ececec' },
    };

    this.frameworkComponents = {
      numericEditor: NumericEditor,
      customTooltip: CustomTooltipComponent,
      buttonAgGridComponent: ButtonAgGridComponent,
      avatarRendererFull: AvatarFullComponent,
      editCellRenderer: EditSelectComponent,
    };
    this.tooltipShowDelay = 0;
    this.getRowHeight = (params: any) => {
      if (params.node.master) {
        return 40;
      }
      // if (params.node.master) {
      //   return params.data.id === 'afbbc56a-4570-452f-9403-75f2b540f90test' ? 0 : 40
      // } else {
      //   if(params.data.productDetailItems && params.data.productDetailItems.length === 1) {
      //     return params.node.uiLevel === 0 ? params.data.productDetailItems.length * 50: params.data.productDetailItems.length * 100
      //   } if(params.data.productDetailItems && params.data.productDetailItems.length > 1 ) {
      //     return params.data.productDetailItems.length * 45
      //   }else {
      //   }
      // }
    };

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Ẩn hiện cột',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
            suppressSyncLayoutWithGrid: true,
            suppressColumnMove: false,
          },
        },
      ],
      defaultToolPanel: '',
    };
  }

  RowDoubleClicked(event) {
    this.rowDoubleClicked.emit(event);
  }

  CellDoubleClicked(event) {
    this.cellDoubleClicked.emit(event);
  }

  sortStateToConsole(event) {
    const sortState = this.gridApi.getSortModel();
    if (sortState.length > 0) {
      localStorage.setItem('sort_col', JSON.stringify(sortState[0]));
    }
  }

  sortByProductNameAsc(data) {
    this.gridColumnApi.applyColumnState({
      state: [{ colId: data?.colId, sort: data?.sort }],
      defaultState: { sort: null },
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    var pivotModeOn: any = document.getElementById(`${this.idGrid}`);
    setTimeout(() => {
      const elem: any = document.getElementsByClassName('hideenRow');
      for (let i = 0; i < elem.length; i++) {
        elem[i].closest('.ag-pivot-off').classList.value =
          elem[i].closest('.ag-pivot-off').classList.value + ' ' + 'hidden';
      }
    }, 1000);
    pivotModeOn?.addEventListener('change', (event: any) => {
      if (
        event.target.ariaLabel ===
          'Press SPACE to toggle visibility (visible)' ||
        event.target.ariaLabel === 'Press SPACE to toggle visibility (hidden)'
      ) {
        let allColumnIds: any = [];
        this.gridColumnApi.getAllColumns().forEach((column: any) => {
          if (
            typeof column.colDef.cellClass === 'object' &&
            column.colDef.cellClass &&
            column.colDef.cellClass.length > 0 &&
            column.colDef.cellClass.indexOf('no-auto') < 0
          ) {
            allColumnIds.push(column);
          } else {
            column.colDef.resizable = false;
          }
          allColumnIds.push(column);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        this.autoSizeAll();
      } else if (
        event.target.ariaLabel ===
          'Press Space to toggle row selection (checked)' ||
        event.target.ariaLabel ===
          'Press Space to toggle row selection (unchecked)' ||
        event.target.ariaLabel ===
          'Press Space to toggle all rows selection (checked)' ||
        event.target.ariaLabel ===
          'Press Space to toggle all rows selection (unchecked)'
      ) {
        this.callback.emit(this.gridApi.getSelectedRows());
      }
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const id = this.activatedRoute.snapshot.queryParams;
    if (id.isBack) {
      const isBack = JSON.parse(localStorage.getItem('sort_col'));
      this.sortByProductNameAsc(isBack);
    }
    let allColumnIds: any = [];
    this.gridColumnApi.getAllColumns().forEach((column: any) => {
      if (
        column.colDef.cellClass &&
        column.colDef.cellClass.length > 0 &&
        column.colDef.cellClass.indexOf('no-auto') < 0
      ) {
        allColumnIds.push(column);
      } else {
        column.colDef.resizable = false;
      }
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
     this.checkAutoSize = this.activatedRoute.data['_value'].url;
    if(this.checkAutoSize === 'inventory-management') {
      setTimeout(() => {
        this.autoSizeAll();
      }, 500);
    }
  }

  ngOnChanges() {
    this.heightAuto = this.height;
    if(this.hideSidebar) {
      this.sideBar = false;
    }
  }

  sizeToFit() {
    if (this.gridApi) {
      let allColumnIds: any = [];
      this.gridColumnApi.getAllColumns().forEach((column: any) => {
        if (
          typeof column.colDef.cellClass === 'object' &&
          column.colDef.cellClass &&
          column.colDef.cellClass.length > 0 &&
          column.colDef.cellClass.indexOf('no-auto') < 0
        ) {
          allColumnIds.push(column);
        } else {
          // column.colDef.suppressSizeToFit = true;
          allColumnIds.push(column);
        }
      });
      this.gridApi.sizeColumnsToFit(allColumnIds);
    }
  }

  getDataAsExcel(event) {
    console.log(event);
  }


  autoSizeAll() {
    if (this.gridColumnApi) {
      if (
        this.gridColumnApi.columnModel.scrollWidth >
        this.gridColumnApi.columnModel.bodyWidth
      ) {
        this.sizeToFit();
      } else {
        let allColumnIds: any = [];
        const checkUrl = this.activatedRoute.data['_value'].url; // Url là chi tiết hoàng hóa
        this.gridColumnApi.getAllColumns().forEach((column: any) => {
          if (
            checkUrl === 'chi-tiet-hang-hoa' ||
            column.colDef.cellClass == null ||
            (typeof column.colDef.cellClass === 'object' &&
              column.colDef.cellClass &&
              column.colDef.cellClass.length > 0 &&
              column.colDef.cellClass.indexOf('no-auto') < 0)
          ) {
            allColumnIds.push(column);
          } else {
            column.colDef.resizable = false;
          }
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, false);
      }
    }
  }

  OnClick(event: any) {
    this.FnClick.emit(event);
  }

  create(label: any) {
    this.callback.emit(label);
  }

  onCellValueChanged(event) {
    this.cellValueChanged.emit(event);
  }

  handleScroll(event: any) {
    if(this.checkAutoSize !== 'inventory-management') {
      this.autoSizeAll();
    }
  }

  // handleScroll(event: any) {
  //   if (this.gridColumnApi) {
  //     const grid = document.getElementById(`${this.idGrid}`);
  //     if (grid) {
  //       // const gridBody = grid.querySelector('.ag-body-viewport') as any;
  //       // const scrollPos = gridBody.offsetHeight + event.top;
  //       // const scrollDiff = gridBody.scrollHeight - scrollPos;
  //       // this.stayScrolledToEnd = (scrollDiff < 1);
  //       // const elem: any = document.getElementsByClassName('hideenRow');
  //       // for(let i = 0 ; i< elem.length ; i++) {
  //       //   elem[i].closest('.ag-pivot-off').classList.value = elem[i].closest('.ag-pivot-off').classList.value +  ' ' +'hidden'
  //       // }
  //       // if (this.stayScrolledToEnd && this.isScrollBottom && ( scrollDiff < 1)) {
  //       //   this.callback.emit(this.gridApi.getSelectedRows())
  //       // }
  //       if ((event.left === 0) ||(event.left > 200 && event.left < 220)
  //         || (event.left > 400 && event.left < 420)
  //         || (event.left > 600 && event.left < 620)
  //         || (event.left > 800 && event.left < 820)
  //         || (event.left > 1200)) {
  //         const gridBody = grid.querySelector('.ag-body-viewport') as any;
  //         setTimeout(() => {
  //           this.autoSizeAll();
  //         }, 300);
  //       }
  //     }
  //   }
  // }

  stayScrolledToEnd = false;
  handleRowDataChanged(event) {
    this.sizeToFit();
    const index = this.listsData.length - this.pageSize;
    if (this.stayScrolledToEnd) {
      this.gridApi.ensureIndexVisible(index, { position: 'bottom' });
    }
  }

  onColumnVisible(event: ColumnVisibleEvent): void {
    this.onColumnVisibleEvent.emit(event);
  }

  onRowSelected(event) {
    this.rowSelected.emit(event.api.getSelectedRows());
  }

  getGridApi() {
    return this.gridApi;
  }
}
