<div class="account-form login d-flex row">
    <div class="left col-6">
        <div class="geo d-flex middle gap10">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_131_655)">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#D80027"/>
                <path d="M12 6.26093L13.295 10.2467H17.4859L14.0954 12.7099L15.3904 16.6957L12 14.2324L8.6095 16.6957L9.90456 12.7099L6.5141 10.2467H10.7049L12 6.26093Z" fill="#FFD600"/>
                </g>
                <defs>
                <clipPath id="clip0_131_655">
                <rect width="24" height="24" rx="12" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <span>VN</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0666 8.53333L5.59998 13.8C5.46665 13.9333 5.33331 14 5.13331 14C4.73331 14 4.46665 13.6667 4.46665 13.3333C4.46665 13.1333 4.53331 13 4.66665 12.8667L9.66665 8L4.66665 3.2C4.53331 3.06667 4.46665 2.86667 4.46665 2.66667C4.46665 2.26667 4.79998 2 5.13331 2C5.33331 2 5.46665 2.06667 5.59998 2.2L11.0666 7.46667C11.2 7.6 11.3333 7.8 11.3333 8C11.3333 8.2 11.2 8.4 11.0666 8.53333Z" fill="#0979FD"/>
            </svg>
        </div>
        <div class="wrap-form">
            <form [formGroup]="loginForm" (ngSubmit)="handleLogin()">
                <div class="text-center">
                    <img src="../../../assets/images/logo.png">
                </div>
                <div class="group">
                    <label for="username">Tên đăng nhập</label>
                    <input formControlName="username" id="username" type="text" name="username" required
                        placeholder="Họ tên của bạn">
                    <div class="text-red-600" *ngIf="(loginFormControl?.username?.touched || submitted) && loginFormControl?.username?.errors?.required">Tên đăng nhập không được bỏ trống</div>
                </div>
                <div class="group">
                    <label for="password">Mật khẩu</label>
                    <input formControlName="password" type="{{ isShowPass ? 'text' : 'password' }}" name="password" required id="password"
                        placeholder="Nhập mật khẩu">
                    <div class="text-red-600" *ngIf="(loginFormControl?.password?.touched || submitted) && loginFormControl?.password?.errors?.required">Mật khẩu không được bỏ trống</div>
                    <svg *ngIf="isShowPass" (click)="isShowPass = !isShowPass" class="show-pass" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 0.5C3.06667 0.5 0.5 8 0.5 8C0.5 8 2.95 15.5 11 15.5C19.2833 15.5 21.5 8 21.5 8C21.5 8 19.2833 0.5 11 0.5ZM11 12.7833C8.31667 12.7833 6.21667 10.5667 6.21667 8C6.21667 5.43333 8.31667 3.21666 11 3.21666C13.6833 3.21666 15.7833 5.43333 15.7833 8C15.7833 10.5667 13.6833 12.7833 11 12.7833Z" fill="#D6DFF6"/>
                        <path d="M11 10.9167C12.6109 10.9167 13.9167 9.61083 13.9167 8C13.9167 6.38916 12.6109 5.08333 11 5.08333C9.38921 5.08333 8.08337 6.38916 8.08337 8C8.08337 9.61083 9.38921 10.9167 11 10.9167Z" fill="#D6DFF6"/>
                    </svg>
                    <svg *ngIf="!isShowPass" class="show-pass" (click)="isShowPass = !isShowPass" width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.59326 0.19501C2.14864 -0.132605 1.52262 -0.0377536 1.19501 0.406867C0.867395 0.851487 0.962246 1.47751 1.40687 1.80512L20.4069 15.8051C20.8515 16.1327 21.4775 16.0379 21.8051 15.5933C22.1327 15.1486 22.0379 14.5226 21.5933 14.195L19.4651 12.6269C21.1207 10.7587 22 9 22 9C22 9 18 1 11 1C9.01942 1 7.27901 1.64044 5.80141 2.55891L2.59326 0.19501ZM8.59344 4.61619L10.5227 6.03776C10.6781 6.01291 10.8376 6 11 6C12.5137 6 13.7656 7.12111 13.9706 8.57831L15.9 9.99997C15.9656 9.67687 16 9.34245 16 9C16 6.23858 13.7614 4 11 4C10.1276 4 9.30737 4.22343 8.59344 4.61619Z" fill="#525B73"/>
                        <path d="M0 9C0 9 1.00945 6.9811 2.90157 4.97172L6.24286 7.45626C6.0852 7.94245 6 8.46128 6 9C6 11.7614 8.23858 14 11 14C12.058 14 13.0392 13.6714 13.8472 13.1107L16.6161 15.1697C15.0523 16.2309 13.1704 17 11 17C4 17 0 9 0 9Z" fill="#525B73"/>
                        <path d="M8 9C8 8.92239 8.00295 8.84547 8.00873 8.76935L12.0827 11.7987C11.7468 11.9287 11.3817 12 11 12C9.34315 12 8 10.6569 8 9Z" fill="#525B73"/>
                    </svg>
                </div>
                <div class="actions mb24">
                    <button [style]="disableLogin ? 'cursor: not-allowed' : ''" [disabled]="disableLogin" class="act login btn">Đăng nhập</button>
                    <p class="color-orange text-center" *ngIf="disableLogin">Tên đăng nhập hoặc mật khẩu không đúng. Vì sự an toàn của tài khoản, việc đăng nhập trên thiết bị này sẽ bị tạm khóa trong 15 phút nếu nhập sai thông tin quá 5 lần.</p>
                    <p class="text-center mt-0" *ngIf="disableLogin">{{timeDown}}</p>
                    <div class="fields">
                        <p-checkbox label="Lưu tài khoản" name="groupname" value="lutaikhoan" [(ngModel)]="lutaikhoan" [ngModelOptions]="{standalone: true}"></p-checkbox>
                    </div>
                </div>
                <span class="line"></span>
                <div class="d-flex bet middle web">
                    <a class="act forgot-pass" style="text-decoration: none;" [routerLink] = "['/forgot-password']">Quên mật khẩu</a>
                    <a class="act forgot-pass" style="text-decoration: none;" href="/register">Đăng ký</a>                    
                </div>
                <div class="text-center mobile">
                    <div class="act forgot-pass" style="text-decoration: none;" [routerLink] = "['/forgot-password']">Quên mật khẩu</div>
                    <div class="act forgot-pass" style="text-decoration: none;" [routerLink] = "['/register']">Đăng ký</div>
                    <p-button label="Click" label="Dùng thử"></p-button>
                </div>
            </form>
            <!-- <div class="goback-home">
                <a class="color-orange" href="https://bizzone.vn/usee/pricing-page">Quay lại trang chủ</a>
            </div> -->
        </div>
    </div>
    <div class="right col-6 bg-register">
        <div class="pd75">
            <div style="visibility: hidden;">&nbsp;</div>
            <h3>Tăng sức mạnh bán hàng đa kênh cùng Usee</h3>
            <ul>
                <li class="d-flex middle" *ngFor="let item of listItem">
                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.65865 10.4092C6.68584 10.4369 6.72325 10.4525 6.76232 10.4525C6.80139 10.4525 6.83879 10.4369 6.86599 10.4092L16.393 0.720833C16.8464 0.259722 17.5797 0.259722 18.0331 0.720833C18.489 1.18443 18.489 1.93784 18.0331 2.40143L7.58237 13.0292C7.12894 13.4903 6.3957 13.4903 5.94227 13.0292L0.716903 7.7153C0.261032 7.25171 0.261032 6.49829 0.716903 6.0347C1.17033 5.57359 1.90358 5.57359 2.35701 6.0347L6.65865 10.4092Z" fill="#34C759"/>
                    </svg>
                    {{ item }}
                </li>
            </ul>
            <small hidden>v1</small>
            <p-button label="Click" label="Dùng thử"></p-button>
            <!-- <img src="../../../assets/images/account/right.png"> -->
        </div>
    </div>
</div>



<p-dialog  [(visible)]="isShowExtendLicense" [showHeader] = "false" [baseZIndex]="99" [modal]="true" [style]="{ width: '700px', height: 'auto' }">
    <div class="grid">
        <div class="col-12 pt-5">
            <div class="text-center">
                <img src="../../../assets/images/logo.png" alt="logo">
            </div>
            <h4 class="pt-3">Thời gian dùng thử miễn phí phần mềm của quý khách đã hết! Để tiếp tục sử dụng phần mềm, quý khách vui lòng liên hệ theo thông tin dưới đây để được hỗ trợ:</h4>
            <p>Hotline: 0987568626</p>
            <p>Email: duongvt1@unicloud.com.vn</p>
        </div>
        <div class="col-12 text-right">
            <button pButton pRipple type="button" label="Quay lại" class="p-button-text" (click) = "isShowExtendLicense = false"></button>
            <!-- <button pButton pRipple type="button" label="Kích hoạt dịch vụ" class="p-button-secondary p-button-text" (click) = "routerBizzone()"></button> -->
        </div>
    </div>
</p-dialog>