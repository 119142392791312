import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';
@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any): string {
    return numeral(value).format('0,0.000');
  }

}
