import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { finalize, lastValueFrom } from 'rxjs';
import { AgGridFn } from 'src/app/common/function-common/common';
import { ListGridAngularComponent } from 'src/app/common/list-grid-angular/list-grid-angular.component';
import { ApiSmartService } from 'src/app/services/api-smart/apismart.service';
const queryString = require('query-string');
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('aggrid1', { static: false }) aggrid1 : ListGridAngularComponent;
  @ViewChild('aggrid2', { static: false }) aggrid2 : ListGridAngularComponent;
  @ViewChild('aggrid3', { static: false }) aggrid3 : ListGridAngularComponent;
  @ViewChild('aggrid4', { static: false }) aggrid4 : ListGridAngularComponent;
  constructor(
    private apiSmart: ApiSmartService,
    ) { }
  moment = moment().utcOffset(0, true);
  revenueTabMenuItems: any = [];
  top20MenuItems : any = [];
  revenuePieMenuItems : any = [];
  top20SellingTabMenuItems: any = [];
  heightGrid = 400;
  timelines = [];
  events: any[];
  fromDate = moment(moment(Date.now()).add(0, 'M')).startOf('month').toDate();
  toDate = new Date();
  store_id = '';
  bestSellingTye: number = 1;
  summaryDataModel;
  yearAgo = moment().subtract(1, 'year').format('YYYY');
  revenueTye: number = 1;
  revenueChart: any = null;
  monthlyRevenneChart : any = null;
  revenueByCategory : any = null;
  bestSellingChart: any = null;
  expired_date;
  hideNoteChart = {
    plugins: {
      legend:{
          display: false
      }
    }
  }
  filterDate: SelectItem[];
  isShowNotice = true;
  stores: any[] = [];
  pieData : any = [];
  ngOnInit(): void {
    const today = new Date();
    const endDate = new Date(localStorage.getItem('expired_date'));
    const days = Math.round(Math.abs((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)));
    this.expired_date = `Bạn còn ${days - 1} ngày sử dụng miễn phí`;
    this.revenuePieMenuItems = [
      {label: 'Theo siêu thị', command: (e) => {this.getRevenueRatioByStoreData()}},
      {label: 'Theo ngành hàng', command: (e) => {this.getRevenueRatioByCategoryData()}},
    ]
    this.top20SellingTabMenuItems = [
      { label: 'Theo doanh thu', icon: '', command: (e) => { this.bestSellingTye = 1; this.getBestSellingProductChartData() } },
      { label: 'Theo số lượng', icon: '', command: (e) => { this.bestSellingTye = 2; this.getBestSellingProductChartData() } },
    ];
    this.getStoresPage();
    this.getProductCategory();
  }

  GetTimeLineData() {
    const opts = { params: new HttpParams({ fromString: `storeId=${this.store_id}` }) };
    this.apiSmart.getTimeLineData(opts.params.toString()).subscribe(rs => {
      if (rs.statusCode) {
        this.timelines = rs.data;
      }
    });
  }

  find() {
    this.load();
  }

  reset() {
    this.fromDate = moment(moment(Date.now()).add(0, 'M')).startOf('month').toDate();
    this.toDate = new Date();
    this.store_id = this.stores[0].value;
    this.load();
  }

  getRevenueRatioByCategoryData() {
    const query = {
      fromDate: moment(this.fromDate).format('yyyy-MM-DD'),
      toDate: moment(this.toDate).format('yyyy-MM-DD')
    }
    const queryParams = queryString.stringify(query);
    this.apiSmart.getRevenueRatioByCategory(this.store_id, queryParams).subscribe(results => {
      const datasets = {
        data: results.data.datasets[0].data,
        backgroundColor: results.data.datasets[0].data.map((d, index) => {
          return this.dynamicColor(index);
        })
      }
      this.pieData = {
        data: {
          labels: results.data.labels,
          datasets: [datasets]
        },
        options: {
          ...this.hideNoteChart
        }
      };
    })
  }

  getRevenueRatioByStoreData() {
    const query = {
      fromDate: moment(this.fromDate).format('yyyy-MM-DD'),
      toDate: moment(this.toDate).format('yyyy-MM-DD')
    }
    const queryParams = queryString.stringify(query);
    this.apiSmart.getRevenueRatioByStore(this.store_id, queryParams).subscribe(results => {
      const datasets = {
        data: results.data.datasets[0].data,
        backgroundColor: results.data.datasets[0].data.map((d, index) => {
          return this.dynamicColor(index);
        })
      }
      this.pieData = {
        data: {
          labels: results.data.labels,
          datasets: [datasets]
        },
        options: {
          ...this.hideNoteChart
        }
      };
    })
  }

  load() {
    this.getSummaryData();
    this.GetTimeLineData();
    this.getRevenueChartData(); //doanh thu theo ngay
    this.getMonthlyRevenueChartData(); //doanh thu theo thang
    this.getRevenueByCategory(); //doanh thu theo nganh hang
    this.getBestSellingProductChartData(); //top 20
    this.getGoodsCancelChartGrid(); //ds hang huy
    this.getProductBelowMin() //ton kho duoi muc toi thieu
    this.getCurrentStock() //ds ton kho
    this.getRevenueRatioByStoreData(); //ty trong doanh thu
    this.getSlowProduct(); //ds ko ban duoc
  }

  getStoresPage() {
    const opts = { params: new HttpParams({ fromString: `offSet=${0}&pageSize=${100000000}` }) };
    this.apiSmart.getStoresByUser(opts.params.toString()).subscribe(results => {
      this.stores = results.data.map(res => {
        return {
          label: `(${res.code}) - ${res.name}`,
          value: res.id
        };
      });
      this.store_id = this.stores[0].value;
      this.load();
    });
  }

  getRevenueByCategory() {
    const queryParams = queryString.stringify({
      fromDate: moment(this.fromDate).format('yyyy-MM-DD'),
      toDate: moment(this.toDate).format('yyyy-MM-DD')
    })
    this.apiSmart.getRevenueByCategoryChart(this.store_id, queryParams).subscribe(results => {
      if(results.status == 'success') {
        const datasets = results.data.datasets.map((d, index) => {
          return {
            label: d.label,
            backgroundColor: `#42A5F${index}`,
            data: d.data,
            borderWidth: 2,
          }
        })
        this.revenueByCategory = {
          data: {
            labels: results.data.labels,
            datasets: datasets
          },
          options: {
            indexAxis: 'y',
            ...this.hideNoteChart
          }
        };
      }
    })
  }

  getMonthlyRevenueChartData() {
    this.apiSmart.getMonthlyRevenueChart(this.store_id)
    .subscribe(results => {
      if(results.status == 'success') {
        const datasets = results.data.datasets.map((d, index) => {
          return {
            label: d.label,
            backgroundColor: index == 0 ? '#0979FD' : '#FF7C59',
            data: d.data,
            borderWidth: 2,
          }
        })
        this.monthlyRevenneChart = {
          data: {
            labels: results.data.labels,
            datasets: datasets
          },
          options: {
            // ...this.hideNoteChart
          }
        };
      }
    })
  }

  changeStore($event) {
    this.load();
  }

  getRevenueChartData() {
    const opts = {
      params: new HttpParams({
        fromString: `storeId=${this.store_id}&type=${this.revenueTye}&from=${moment(this.fromDate).format('yyyy-MM-DD')}&to=${moment(this.toDate).format('yyyy-MM-DD')}`
      })
    };
    this.apiSmart.getRevenueChartData(opts.params.toString()).subscribe(rs => {
      if (rs.statusCode) {
        const datasets = rs.data.datasets.map((d, index) => {
          return {
            label: d.label,
            backgroundColor: `#42A5F${index}`,
            borderColor: `#42A5F${index}`,
            data: d.data,
            borderWidth: 2,
            pointRadius: 0
          }
        })
        this.revenueChart = {
          data: {
            labels: rs.data.labels,
            datasets: datasets
          },
          options: {
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                display: false,
                grid: {
                  display: false
                }
              }
            },
            ...this.hideNoteChart
          }
        };
      }
    });
  }

  getBestSellingProductChartData() {
    const opts = { params: new HttpParams({ fromString: `storeId=${this.store_id}&type=${this.bestSellingTye}&from=${moment(this.fromDate).format('yyyy-MM-DD')}&to=${moment(this.toDate).format('yyyy-MM-DD')}` }) };
    this.apiSmart.getBestSellingProductChartData(opts.params.toString()).subscribe(rs => {
      if (rs.statusCode) {
        const datasets = rs.data.datasets.map((d, index) => {
          return {
            label: d.label,
            backgroundColor: `#42A5F${index}`,
            data: d.data
          }
        })
        this.bestSellingChart = {
          data: {
            labels: rs.data.labels,
            datasets: datasets
          },
          options: {
            indexAxis: 'y',
            ...this.hideNoteChart
          }
        };
      }
    });
  }

  getSummaryData() {
    const query = {
      storeId: this.store_id,
      fromDate: moment(this.fromDate).format('yyyy-MM-DD'),
      toDate: moment(this.toDate).format('yyyy-MM-DD')
    }
    const queryParams = queryString.stringify(query)
    this.apiSmart.getSummaryDashboard(queryParams).subscribe(rs => {
      if (rs.statusCode) {
        this.summaryDataModel = rs.data
      }
    });
  }

  closeNotice() {
    this.isShowNotice = false;
  }

  categoryOptions : any = [];
  categorySelected = '';
  getProductCategory () {
    const queryParams = queryString.stringify({level: 1})
    this.apiSmart.categorySearch(queryParams).subscribe(results => {
      if(results.status === 'success') {
        this.categoryOptions = results.data.map(item => {
          return {
            label: item.text,
            value: item.value
          }
        })
        this.categoryOptions = [{label: 'Tất cả', value: ''}, ...this.categoryOptions]
      }
    })
  }

  detectChange(key, value) {
    if(key === 'fromDate') {
      this.fromDate = value
    } else {
      this.toDate = value
    }
  }

  //ds hang huy
  gridData =  {
    goodCancelData: [],
    productBelowMinData: [],
    currentStockData: [],
    slowSellingData: []
  }
  gridColumn = {
    goodCancelCol: [],
    productBelowMinCol: [],
    currentStockCol: [],
    slowSellingCol : []
  }

  async getGoodsCancelChartGrid() {
    const query = {
      fromDate: moment(this.fromDate).format('yyyy-MM-DD'),
      toDate: moment(this.toDate).format('yyyy-MM-DD')
    }
    const queryParams = queryString.stringify(query);
    this.apiSmart.getGoodsCancelGrid(this.store_id, queryParams).subscribe(async results => {
      if(results.status === 'success') {
        this.gridData.goodCancelData = results.data.dataList.data;
        const {
          gridName,
        } = results.data.gridInfo;
        const configGrid = await lastValueFrom(this.apiSmart.configurationUserGrid(gridName));
        const gridflexs = configGrid.data.map(t => ({...t, cellClass: t.cellClass ? t.cellClass.split(',') : '', ordinal: t.ordinal || 0})).sort((a, b) => a.ordinal - b.ordinal);
        this.gridColumn.goodCancelCol = [...AgGridFn(gridflexs)];
        setTimeout(() => {
          this.aggrid1.autoSizeAll()
        }, 100)
      }
    })
  }

  async getProductBelowMin() {
    this.apiSmart.getProductsBelowMinGrid(this.store_id).subscribe(async results => {
      if(results.status === 'success') {
        this.gridData.productBelowMinData = results.data.dataList.data;
        const {
          gridName,
        } = results.data.gridInfo;
        const configGrid = await lastValueFrom(this.apiSmart.configurationUserGrid(gridName));
        const gridflexs = configGrid.data.map(t => ({...t, cellClass: t.cellClass ? t.cellClass.split(',') : '', ordinal: t.ordinal || 0})).sort((a, b) => a.ordinal - b.ordinal);
        this.gridColumn.productBelowMinCol = [...AgGridFn(gridflexs)];
        setTimeout(() => {
          this.aggrid2.autoSizeAll()
        }, 100)
      }
    })
  }
  async getCurrentStock() {
    this.apiSmart.getCurrentStockGrid(this.store_id).subscribe(async results => {
      if(results.status === 'success') {
        this.gridData.currentStockData = results.data.dataList.data;
        const {
          gridName,
        } = results.data.gridInfo;
        const configGrid = await lastValueFrom(this.apiSmart.configurationUserGrid(gridName));
        const gridflexs = configGrid.data.map(t => ({...t, cellClass: t.cellClass ? t.cellClass.split(',') : '', ordinal: t.ordinal || 0})).sort((a, b) => a.ordinal - b.ordinal);
        this.gridColumn.currentStockCol = [...AgGridFn(gridflexs)]
        setTimeout(() => {
          this.aggrid4.autoSizeAll()
        }, 100)
      }
    })
  }

  async getSlowProduct() {
    this.apiSmart.getSlowSellingProduct(this.store_id).subscribe(async results => {
      if(results.status === 'success') {
        this.gridData.slowSellingData = results.data.dataList.data;
        const {
          gridName,
        } = results.data.gridInfo;
        const configGrid = await lastValueFrom(this.apiSmart.configurationUserGrid(gridName));
        const gridflexs = configGrid.data.map(t => ({...t, cellClass: t.cellClass ? t.cellClass.split(',') : '', ordinal: t.ordinal || 0})).sort((a, b) => a.ordinal - b.ordinal);
        this.gridColumn.slowSellingCol = [...AgGridFn(gridflexs)];
        setTimeout(() => {
          this.aggrid3.autoSizeAll()
        }, 100)
      }
    })
  }

  dynamicColor(index) {
    const colorStyle = ['#3079d5', '#dc4baa', '#228B22', '#FF6347', '#0fc45b', '#ffba00', '#ff6609', '#ec1431', '#4169E1', '#0fc45b', '#ff6609', '#f302bf', '#20B2AA',          '#EE82EE', '#ffba00', '#3079d5', '#7B68EE', '#1dc2da', '#0fc45b', '#3079d5', '#dc4baa', '#ff6609', '#f302bf', '#06f006', '#3079d5', '#1dc2da', '#0fc45b', '#dc4baa', '#0fc45b', '#3079d5', '#dc4baa', '#ff6609', '#f302bf', '#06f006', '#3079d5', '#1dc2da', '#0fc45b', '#dc4baa'];
    return colorStyle[index];
  }
}
