import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes,  } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DefaultLayoutCreateOrderComponent } from './containers/default-layout-create-order/default-layout-create-order';
import { DefaultLayoutCreatePurchaseOrderComponent } from './containers/default-layout-create-purchase-order/default-layout-create-purchase-order';
import { LayoutLoginComponent } from './containers/layout-login/layout-login.component';
import { ThongTinCaNhanComponent } from './components/thong-tin-ca-nhan/thong-tin-ca-nhan.component';
import { DefaultLayoutCustomerComponent } from './containers/default-layout-customer-info/default-layout-customer-info';
import { ImportExcelComponent } from './components/import-excel/import-excel.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HealthComponent } from './components/health/health.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'health', component: HealthComponent
  },
  { path: 'login', component: LoginComponent, pathMatch: 'full', },
  { path: 'register', component: RegisterComponent, pathMatch: 'full', },
  { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full', },
  {
    path: '', component: LayoutLoginComponent,
    data: { title: '' }, children: [
        { path: 'home', component: HomeComponent, canActivate: [AuthGuardService]},
        {
          path: 'catalog',
          loadChildren: () => import('../app/pages/danh-muc/danh-muc.module').then(m => m.DanhMucModule)
        },
        {
          path: 'manager',
          loadChildren: () => import('../app/pages/quan-tri/quan-tri.module').then(m => m.QuanTriModule)
        },
        {
          path: 'partner',
          loadChildren: () => import('../app/pages/doi-tac/doi-tac.module').then(m => m.DoiTacModule)
        },
        {
          path: 'exchange',
          loadChildren: () => import('../app/pages/giao-dich/giao-dich.module').then(m => m.GiaoDichModule)
        },
        {
          path: 'product',
          loadChildren: () => import('../app/pages/hang-hoa/hang-hoa.module').then(m => m.HangHoaModule)
        },
        {
          path: 'user',
          loadChildren: () => import('../app/pages/nhan-vien/nhan-vien.module').then(m => m.NhanVienSieuThiModule)
        },
        {
          path: 'report',
          loadChildren: () => import('../app/pages/bao-cao/bao-cao.module').then(m => m.BaoCaoModule)
        },
        {
          path: 'thu-chi',
          loadChildren: () => import('../app/pages/phieu-thu-chi/phieu-thu-chi.module').then(m => m.PhieuThuChiModule)
        }
    ]
  },
  {
    path: 'order', component: DefaultLayoutCreateOrderComponent,
    data: { title: '' }, children: [
      {
        path: '', loadChildren: () => import('../app/pages/quan-tri/quan-tri.module').then(m => m.QuanTriModule)
      }
    ], canActivate: [AuthGuardService]
  },
  {
    path: 'user-profile', component: ThongTinCaNhanComponent,
    data: { title: '' }, canActivate: [AuthGuardService]
  },
  {
    path: 'purchase-order', component: DefaultLayoutCreatePurchaseOrderComponent,
    data: { title: '' }, children: [
      { path: '', loadChildren: () => import('../app/pages/quan-tri/quan-tri.module').then(m => m.QuanTriModule)},
    ], canActivate: [AuthGuardService]
  },
  {
    path: 'promotion-store', component: DefaultLayoutCreatePurchaseOrderComponent,
    data: { title: '' }, children: [
      { path: '', loadChildren: () => import('../app/pages/danh-muc/danh-muc.module').then(m => m.DanhMucModule)},
    ], canActivate: [AuthGuardService]
  },
  {
    path: 'promotion', component: DefaultLayoutCreatePurchaseOrderComponent,
    data: { title: '' }, children: [
      { path: '', loadChildren: () => import('../app/pages/quan-tri/quan-tri.module').then(m => m.QuanTriModule)},
    ], canActivate: [AuthGuardService]
  },
  {
    path: 'plan-price', component: DefaultLayoutCreatePurchaseOrderComponent,
    data: { title: '' }, children: [
      { path: '', loadChildren: () => import('../app/pages/quan-tri/quan-tri.module').then(m => m.QuanTriModule)},
    ], canActivate: [AuthGuardService]
  },
  {
    path: 'bundle-promotion', component: DefaultLayoutCreatePurchaseOrderComponent,
    data: { title: '' }, children: [
      { path: '', loadChildren: () => import('../app/pages/hang-hoa/hang-hoa.module').then(m => m.HangHoaModule)},
    ], canActivate: [AuthGuardService]
  },
  {
    path: 'khach-hang', component: DefaultLayoutCustomerComponent,
    data: { title: '' }, children: [
      { path: '', loadChildren: () => import('../app/pages/khach-hang/khach-hang.module').then(m => m.KhachHangModule)},
    ], canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
