<button class="icon-filter" (click)="onHandleOpenModalFilter()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.8007 5.47726H2.76923C2.3444 5.47726 2 5.14148 2 4.72726C2 4.31305 2.3444 3.97726 2.76923 3.97726H13.9953C14.3678 3.24874 15.1256 2.75 16 2.75C16.8766 2.75 17.6362 3.25134 18.0076 3.98294C18.0387 3.9792 18.0704 3.97726 18.1026 3.97726H21.2309C21.6557 3.97726 22.0001 4.31305 22.0001 4.72726C22.0001 5.14148 21.6557 5.47726 21.2309 5.47726H18.1993C17.9804 6.49066 17.0789 7.25 16 7.25C14.9211 7.25 14.0196 6.49066 13.8007 5.47726Z"
            fill="black" />
        <path
            d="M6.87802 12.75H2.76923C2.3444 12.75 2 12.4142 2 12C2 11.5858 2.3444 11.25 2.76923 11.25H6.87804C7.18693 10.3761 8.02035 9.75 9 9.75C9.97965 9.75 10.8131 10.3761 11.122 11.25H21.2308C21.6557 11.25 22.0001 11.5858 22.0001 12C22.0001 12.4142 21.6557 12.75 21.2308 12.75H11.122C10.8131 13.6239 9.97968 14.25 9 14.25C8.02032 14.25 7.18689 13.6239 6.87802 12.75Z"
            fill="black" />
        <path
            d="M11.8007 18.5227C12.0196 17.5093 12.9211 16.75 14 16.75C15.0789 16.75 15.9804 17.5093 16.1993 18.5227H21.2308C21.6557 18.5227 22.0001 18.8585 22.0001 19.2727C22.0001 19.6869 21.6557 20.0227 21.2308 20.0227H16.0514C16.0359 20.0227 16.0206 20.0222 16.0054 20.0214C15.6332 20.7506 14.8749 21.25 14 21.25C13.1251 21.25 12.3668 20.7506 11.9947 20.0214C11.9795 20.0223 11.9641 20.0227 11.9487 20.0227H2.76923C2.3444 20.0227 2 19.6869 2 19.2727C2 18.8585 2.3444 18.5227 2.76923 18.5227H11.8007Z"
            fill="black" />
    </svg>
</button>