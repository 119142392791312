<div class="account-form login d-flex row">
    <!-- <div class="right col-6 bg-register">
        <img src="../../../assets/images/account/right.png">
    </div> -->
    <div class="col-4 web"></div>
    <div class="left col-4">
        <div *ngIf="step == 'FindStore'" class="wrap-form">
            <div>
                <div class="group">
                    <label for="username">Nhập tên đăng nhập</label>
                    <input [(ngModel)]="resetPassword.store_name" id="username" type="text" name="username" required
                        placeholder="Tên đăng nhập của bạn">
                </div>
                <div class="actions">
                    <button (click)="sendOtp()" class="act login btn">Tiếp tục</button>
                </div>
            </div>
            <span class="line"></span>
            <div class="goback-home">
                <a class="color-orange" href="https://bizzone.vn/usee/pricing-page">Quay lại trang chủ</a>
            </div>
        </div>
        <div *ngIf="step == 'VerifyOtp'" class="wrap-form">
            <div class="grid">
                <div class="col-6">
                    <div class="group">
                        <label for="">&nbsp;</label>
                        <!-- class 'sent': đã gửi, remove sent: chưa gửi -->
                        <button class="btn-input sent" (click)="sendOtp()">Gửi <span *ngIf="isSent">lại</span> mã
                            xác thực <span *ngIf="isSent" class="time" style="color: red;">({{ counter }}
                                s)</span></button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="group mxt">
                        <label for="">Mã xác thực <span style="color: red">*</span></label>
                        <input [(ngModel)]="resetPassword.otp" placeholder="Nhập mã xác thực" name="otp">

                    </div>
                </div>
                <div class="actions col-12">
                    <button (click)="verifyOtp()" [style.background]="isSent ? '' : '#979797 !important'"
                        class="act login btn">Tiếp tục</button>
                </div>
            </div>
            <span class="line"></span>
            <div class="goback-home">
                <a class="color-orange" href="https://bizzone.vn/usee/pricing-page">Quay lại trang chủ</a>
            </div>
        </div>
        <div class="wrap-form" *ngIf="step == 'ChangePassword'">
            <div>
                <div class="group">
                    <label for="username">Nhập mật khẩu mới</label>
                    <div class="icon-wrapper">
                        <input [(ngModel)]="resetPassword.password" id="username" [type]="status.showPassword ? 'text' : 'password'"
                            placeholder="Nhập mật khẩu mới">
                        <i class="pi pi-eye" *ngIf = "!status.showPassword" (click) = "status.showPassword = !status.showPassword"></i>
                        <i class="pi pi-eye-slash" *ngIf = "status.showPassword" (click) = "status.showPassword = !status.showPassword"></i>
                    </div>
                </div>
                <div class="group">
                    <label for="username">Nhập lại mật khẩu mới</label>
                    <div class="icon-wrapper">
                        <input [(ngModel)]="resetPassword.rePassword" id="username" [type]="status.showConfirmPassword ? 'text' : 'password'"
                            placeholder="Nhập lại mật khẩu mới">
                        <i class="pi pi-eye" *ngIf = "!status.showConfirmPassword" (click) = "status.showConfirmPassword = !status.showConfirmPassword"></i>
                        <i class="pi pi-eye-slash" *ngIf = "status.showConfirmPassword" (click) = "status.showConfirmPassword = !status.showConfirmPassword"></i>
                    </div>
                </div>
                <div class="actions">
                    <button (click)="ChangePassword()" class="act login btn">Đổi mật khẩu</button>
                </div>
            </div>
            <span class="line"></span>
            <div class="goback-home">
                <a class="color-orange" href="https://bizzone.vn/usee/pricing-page">Quay lại trang chủ</a>
            </div>
        </div>
        <div *ngIf="step == 'Done'" class="wrap-form">
            <div class="text-center">
                <span for="username">Mật khẩu của quý khách đã được cập nhật thành công</span>
                
            </div>
            <div class="actions" style="margin-top: 10px;">
                <button (click)="goLogin()" class="act login btn">Quay lại trang đăng nhập</button>
            </div>
            <span class="line"></span>
        </div>

    </div>
    <div class="col-4 web"></div>
</div>