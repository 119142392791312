<div class="section" style="margin-top: 5px;">
  <ag-grid-angular
    [style.height]="heightAuto + 'px'"
    [excelStyles]="excelStyles"
    [getContextMenuItems]="getContextMenuItems"
     #agGrid style="width: 100% "
    id="{{idGrid}}"
    (sortChanged)="sortStateToConsole($event)"
    class="ag-theme-alpine"
    [pinnedTopRowData]="pinnedTopRowData"
    [domLayout]="domLayout"
    [defaultExcelExportParams]="excelExportParams"
    [defaultCsvExportParams]="csvExportParams"
    [rowData]="listsData"
    [columnDefs]="columnDefs"
    [skipHeaderOnAutoSize]="false"
    [floatingFilter]="floatingFilter"
    [isRowMaster]="isRowMaster"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [masterDetail]="true"
    [modules]="modules"
    [rowClassRules]="rowClassRules"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [detailCellRendererParams]="detailCellRendererParams"
    [tooltipShowDelay]="tooltipShowDelay"
    [suppressRowClickSelection]="true"
    [rowSelection]="rowSelection"
    (gridReady)="onGridReady($event)"
    (rowDoubleClicked)="RowDoubleClicked($event)"
    (cellDoubleClicked)="CellDoubleClicked($event)"
    (cellValueChanged)="onCellValueChanged($event)" 
    [defaultColDef]="defaultColDef"
    [enableCellTextSelection]="false"
    [groupDisplayType]="'groupRows'"
    [enableRangeSelection]="false"
    [getRowHeight]="getRowHeight"
    [singleClickEdit]="true" [valueCache]="true"
    [headerHeight]="headerHeight"
    [accentedSort]="true"
    [rowClass]="'my-row'"
    (bodyScroll)="handleScroll($event)"
    (rowDataChanged)="handleRowDataChanged($event)"
    [sideBar]="sideBar"
    (columnVisible)="onColumnVisible($event)"
    (selectionChanged)="onRowSelected($event)"
    [frameworkComponents]="frameworkComponents">
  </ag-grid-angular>
</div>
<!-- [embedFullWidthRows]="true" -->
