export const AUTH_CONSTANT = {
    TOKEN: 'token-usee',
    REFRESH_TOKEN: 'refresh-token-usee',
    REFRESH_TOKEN_BY_IN: 'refresh-token-by-in-usee',
    SESSION_STATE: 'session-state-usee',
    TOKEN_TYPE: 'Bearer-usee',
    SCOPE: 'scope-usee',
    EXPIRES_IN: 'expire-in-usee',
    ID_TOKEN: 'id-token-usee',
    NAME : 'name',
    AVATAR_URL : 'avatar_url',
    USER_ID : 'user_id',
    USER_NAME : 'user_name',
    MENU_ITEMS: 'menuItems'
}