<div class="grid main-grid">
    <div class="col-12 right max-w-full">
        <div class="breadcrumb">
            <h3>Thông tin tài khoản</h3>
            <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
        <div class="white-background">
            <div class="head grid">
                <div class="col-9">
                    <p-tabMenu class="menu-timeline menu-tab" [model]="menus" [activeItem]="menus[0]"></p-tabMenu>
                </div>
                <div class="col-3 right_content">
                    <button *ngIf="action == 'UserUpdate'" class="mr-1" type="button"
                        (click)="saveData()" pButton icon="pi pi-check" label="Lưu lại" style="float:right;"></button>
                    <button *ngIf="action == 'ChangePassword'" class="mr-1" type="button"
                        (click)="SavePassword()" pButton icon="pi pi-check" label="Lưu lại" style="float:right;"></button>
                    <button *ngIf="action == 'UserUpdate' || action == 'ChangePassword'" type="button"
                        class="p-button-secondary btn-cancel mr-1" pButton icon="pi pi-times" label="Hủy bỏ" (click)="CancerData()" 
                        style="float:right;"></button>
                </div>
                <div *ngIf="action == 'UserInfo'" class="grid col-12">
                    <div class="col-2">
                        <div class="wrap-image field-upload-image viewImage">
                            <p-image src="{{ imgUrl }}" alt="Image" height="500" width="500" [preview]="true"></p-image>
                            <label class="upload" for="myfiless1">
                            </label>
                            <input 
                                  accept="image/jpeg,image/png,image/jpg,image/gif" type="file" style="display: none" id="myfiless1" name="myfiless1" class="myfile1">
                            </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group">
                            <label class="text-nowrap label-text">Tên đăng nhập</label>
                            <input [ngModel]="user.loginName" type="text" class="form-control" disabled>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Số điện thoại</label>
                            <input [ngModel]="user.phone" type="text" class="form-control" disabled>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Thư điện tử</label>
                            <input [ngModel]="user.email" type="text" class="form-control" disabled>
                        </div>
                    </div>
                    <div class="col-4">
                        <!-- <div class="input-group">
                            <label class="text-nowrap label-text">Người quản lý</label>
                            <input type="text" class="form-control" disabled>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Vị trí / chức vụ</label>
                            <input type="text" class="form-control" disabled>
                        </div> -->
                        <div class="input-group">
                            <label class="text-nowrap label-text">Tên tài khoản</label>
                            <input type="text" class="form-control" disabled [ngModel]="user.name">
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Nhóm quyền</label>
                            <input type="text" class="form-control" disabled [ngModel]="user.role">
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Cửa hàng</label>
                            <input type="text" class="form-control" [ngModel]="user.stores" disabled>
                        </div>
                    </div>
                    <div class="col-2">

                    </div>

                </div>
                <div *ngIf="action == 'UserUpdate'" class="grid col-12">
                    <div class="col-2">
                        <div class="wrap-image field-upload-image">
                            <p-image src="{{ imgUrl }}" alt="Image" height="500" width="500" [preview]="true"></p-image>
                            <label class="upload" for="myfiless1">
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#0979FD"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8582 11.8325C16.993 11.6252 17.2242 11.5 17.4723 11.5H22.5277C22.7758 11.5 23.007 11.6252 23.1418 11.8325L24.6107 14.0905H27.5831C28.2311 14.0905 28.848 14.3544 29.2995 14.8172C29.7503 15.2792 30 15.9014 30 16.546V26.0444C30 26.689 29.7503 27.3113 29.2995 27.7733C28.848 28.236 28.2311 28.5 27.5831 28.5H12.4169C11.7689 28.5 11.152 28.236 10.7005 27.7733C10.2497 27.3113 10 26.689 10 26.0444V16.546C10 15.9014 10.2497 15.2792 10.7005 14.8172C11.152 14.3544 11.7689 14.0905 12.4169 14.0905H15.3893L16.8582 11.8325ZM17.6587 20.862C17.6587 19.5087 18.7243 18.4442 19.9992 18.4442C21.2741 18.4442 22.3397 19.5087 22.3397 20.862C22.3397 22.2153 21.2741 23.2798 19.9992 23.2798C18.7243 23.2798 17.6587 22.2153 17.6587 20.862ZM19.9992 16.3718C17.5514 16.3718 15.5991 18.4001 15.5991 20.862C15.5991 23.3238 17.5514 25.3521 19.9992 25.3521C22.4471 25.3521 24.3993 23.3238 24.3993 20.862C24.3993 18.4001 22.4471 16.3718 19.9992 16.3718Z" fill="white"/>
                              </svg>
                            </label>
                            <input (change)="onUploadOutput($event)"
                                  accept="image/jpeg,image/png,image/jpg,image/gif" type="file" style="display: none" id="myfiless1" name="myfiless1" class="myfile1">
                            </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group">
                            <label class="text-nowrap label-text">Tên đăng nhập</label>
                            <input [(ngModel)]="user.loginName" type="text" class="form-control" disabled>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Số điện thoại</label>
                            <input [(ngModel)]="user.phone" type="number" class="form-control">
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Thư điện tử</label>
                            <input [(ngModel)]="user.email" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-4">
                        <!-- <div class="input-group">
                            <label class="text-nowrap label-text">Người quản lý</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Vị trí / chức vụ</label>
                            <input type="text" class="form-control">
                        </div> -->
                        <div class="input-group">
                            <label class="text-nowrap label-text">Tên tài khoản</label>
                            <input [(ngModel)]="user.name" type="text" class="form-control">
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Nhóm quyền</label>
                            <input [ngModel]="user.role" type="text" class="form-control" disabled>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Cửa hàng</label>
                            <input [ngModel]="user.stores" type="text" class="form-control" disabled>
                        </div>
                    </div>

                    <div class="col-2">

                    </div>

                </div>
                <div *ngIf="action == 'ChangePassword'" class="grid col-12">
                    <div class="col-8">
                        <div class="input-group">
                            <label class="text-nowrap label-text">Tên đăng nhập <span
                                    style="color: red">*</span></label>
                            <input [(ngModel)]="changePassword.loginName" type="text" class="form-control" disabled>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Mật khẩu cũ <span style="color: red">*</span></label>
                            <input [(ngModel)]="changePassword.oldPassword"
                                type="{{ isShowPass ? 'text' : 'password' }}" class="form-control">
                            <div class="show-password" style="position: absolute; right: 10px; top: 37px">
                                <svg *ngIf="isShowPass" (click)="isShowPass = !isShowPass" class="show-pass" width="22"
                                    height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11 0.5C3.06667 0.5 0.5 8 0.5 8C0.5 8 2.95 15.5 11 15.5C19.2833 15.5 21.5 8 21.5 8C21.5 8 19.2833 0.5 11 0.5ZM11 12.7833C8.31667 12.7833 6.21667 10.5667 6.21667 8C6.21667 5.43333 8.31667 3.21666 11 3.21666C13.6833 3.21666 15.7833 5.43333 15.7833 8C15.7833 10.5667 13.6833 12.7833 11 12.7833Z"
                                        fill="#D6DFF6" />
                                    <path
                                        d="M11 10.9167C12.6109 10.9167 13.9167 9.61083 13.9167 8C13.9167 6.38916 12.6109 5.08333 11 5.08333C9.38921 5.08333 8.08337 6.38916 8.08337 8C8.08337 9.61083 9.38921 10.9167 11 10.9167Z"
                                        fill="#D6DFF6" />
                                </svg>
                                <svg *ngIf="!isShowPass" class="show-pass" (click)="isShowPass = !isShowPass" width="22"
                                    height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2.59326 0.19501C2.14864 -0.132605 1.52262 -0.0377536 1.19501 0.406867C0.867395 0.851487 0.962246 1.47751 1.40687 1.80512L20.4069 15.8051C20.8515 16.1327 21.4775 16.0379 21.8051 15.5933C22.1327 15.1486 22.0379 14.5226 21.5933 14.195L19.4651 12.6269C21.1207 10.7587 22 9 22 9C22 9 18 1 11 1C9.01942 1 7.27901 1.64044 5.80141 2.55891L2.59326 0.19501ZM8.59344 4.61619L10.5227 6.03776C10.6781 6.01291 10.8376 6 11 6C12.5137 6 13.7656 7.12111 13.9706 8.57831L15.9 9.99997C15.9656 9.67687 16 9.34245 16 9C16 6.23858 13.7614 4 11 4C10.1276 4 9.30737 4.22343 8.59344 4.61619Z"
                                        fill="#525B73" />
                                    <path
                                        d="M0 9C0 9 1.00945 6.9811 2.90157 4.97172L6.24286 7.45626C6.0852 7.94245 6 8.46128 6 9C6 11.7614 8.23858 14 11 14C12.058 14 13.0392 13.6714 13.8472 13.1107L16.6161 15.1697C15.0523 16.2309 13.1704 17 11 17C4 17 0 9 0 9Z"
                                        fill="#525B73" />
                                    <path
                                        d="M8 9C8 8.92239 8.00295 8.84547 8.00873 8.76935L12.0827 11.7987C11.7468 11.9287 11.3817 12 11 12C9.34315 12 8 10.6569 8 9Z"
                                        fill="#525B73" />
                                </svg>
                            </div>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Mật khẩu mới <span style="color: red">*</span></label>
                            <input [(ngModel)]="changePassword.newPassword"
                                type="{{ isShowPassNew ? 'text' : 'password' }}" class="form-control">
                            <div class="show-password" style="position: absolute; right: 10px; top: 37px">
                                <svg *ngIf="isShowPassNew" (click)="isShowPassNew = !isShowPassNew" class="show-pass"
                                    width="22" height="16" viewBox="0 0 22 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11 0.5C3.06667 0.5 0.5 8 0.5 8C0.5 8 2.95 15.5 11 15.5C19.2833 15.5 21.5 8 21.5 8C21.5 8 19.2833 0.5 11 0.5ZM11 12.7833C8.31667 12.7833 6.21667 10.5667 6.21667 8C6.21667 5.43333 8.31667 3.21666 11 3.21666C13.6833 3.21666 15.7833 5.43333 15.7833 8C15.7833 10.5667 13.6833 12.7833 11 12.7833Z"
                                        fill="#D6DFF6" />
                                    <path
                                        d="M11 10.9167C12.6109 10.9167 13.9167 9.61083 13.9167 8C13.9167 6.38916 12.6109 5.08333 11 5.08333C9.38921 5.08333 8.08337 6.38916 8.08337 8C8.08337 9.61083 9.38921 10.9167 11 10.9167Z"
                                        fill="#D6DFF6" />
                                </svg>
                                <svg *ngIf="!isShowPassNew" class="show-pass" (click)="isShowPassNew = !isShowPassNew"
                                    width="22" height="17" viewBox="0 0 22 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2.59326 0.19501C2.14864 -0.132605 1.52262 -0.0377536 1.19501 0.406867C0.867395 0.851487 0.962246 1.47751 1.40687 1.80512L20.4069 15.8051C20.8515 16.1327 21.4775 16.0379 21.8051 15.5933C22.1327 15.1486 22.0379 14.5226 21.5933 14.195L19.4651 12.6269C21.1207 10.7587 22 9 22 9C22 9 18 1 11 1C9.01942 1 7.27901 1.64044 5.80141 2.55891L2.59326 0.19501ZM8.59344 4.61619L10.5227 6.03776C10.6781 6.01291 10.8376 6 11 6C12.5137 6 13.7656 7.12111 13.9706 8.57831L15.9 9.99997C15.9656 9.67687 16 9.34245 16 9C16 6.23858 13.7614 4 11 4C10.1276 4 9.30737 4.22343 8.59344 4.61619Z"
                                        fill="#525B73" />
                                    <path
                                        d="M0 9C0 9 1.00945 6.9811 2.90157 4.97172L6.24286 7.45626C6.0852 7.94245 6 8.46128 6 9C6 11.7614 8.23858 14 11 14C12.058 14 13.0392 13.6714 13.8472 13.1107L16.6161 15.1697C15.0523 16.2309 13.1704 17 11 17C4 17 0 9 0 9Z"
                                        fill="#525B73" />
                                    <path
                                        d="M8 9C8 8.92239 8.00295 8.84547 8.00873 8.76935L12.0827 11.7987C11.7468 11.9287 11.3817 12 11 12C9.34315 12 8 10.6569 8 9Z"
                                        fill="#525B73" />
                                </svg>
                            </div>
                        </div>
                        <div class="input-group">
                            <label class="text-nowrap label-text">Nhập lại mật khẩu mới <span
                                    style="color: red">*</span></label>
                            <input [(ngModel)]="changePassword.repeatPassword"
                                type="{{ isShowPassConfirm ? 'text' : 'password' }}" class="form-control">
                            <div class="show-password" style="position: absolute; right: 10px; top: 37px">
                                <svg *ngIf="isShowPassConfirm" (click)="isShowPassConfirm = !isShowPassConfirm"
                                    class="show-pass" width="22" height="16" viewBox="0 0 22 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11 0.5C3.06667 0.5 0.5 8 0.5 8C0.5 8 2.95 15.5 11 15.5C19.2833 15.5 21.5 8 21.5 8C21.5 8 19.2833 0.5 11 0.5ZM11 12.7833C8.31667 12.7833 6.21667 10.5667 6.21667 8C6.21667 5.43333 8.31667 3.21666 11 3.21666C13.6833 3.21666 15.7833 5.43333 15.7833 8C15.7833 10.5667 13.6833 12.7833 11 12.7833Z"
                                        fill="#D6DFF6" />
                                    <path
                                        d="M11 10.9167C12.6109 10.9167 13.9167 9.61083 13.9167 8C13.9167 6.38916 12.6109 5.08333 11 5.08333C9.38921 5.08333 8.08337 6.38916 8.08337 8C8.08337 9.61083 9.38921 10.9167 11 10.9167Z"
                                        fill="#D6DFF6" />
                                </svg>
                                <svg *ngIf="!isShowPassConfirm" class="show-pass"
                                    (click)="isShowPassConfirm = !isShowPassConfirm" width="22" height="17"
                                    viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2.59326 0.19501C2.14864 -0.132605 1.52262 -0.0377536 1.19501 0.406867C0.867395 0.851487 0.962246 1.47751 1.40687 1.80512L20.4069 15.8051C20.8515 16.1327 21.4775 16.0379 21.8051 15.5933C22.1327 15.1486 22.0379 14.5226 21.5933 14.195L19.4651 12.6269C21.1207 10.7587 22 9 22 9C22 9 18 1 11 1C9.01942 1 7.27901 1.64044 5.80141 2.55891L2.59326 0.19501ZM8.59344 4.61619L10.5227 6.03776C10.6781 6.01291 10.8376 6 11 6C12.5137 6 13.7656 7.12111 13.9706 8.57831L15.9 9.99997C15.9656 9.67687 16 9.34245 16 9C16 6.23858 13.7614 4 11 4C10.1276 4 9.30737 4.22343 8.59344 4.61619Z"
                                        fill="#525B73" />
                                    <path
                                        d="M0 9C0 9 1.00945 6.9811 2.90157 4.97172L6.24286 7.45626C6.0852 7.94245 6 8.46128 6 9C6 11.7614 8.23858 14 11 14C12.058 14 13.0392 13.6714 13.8472 13.1107L16.6161 15.1697C15.0523 16.2309 13.1704 17 11 17C4 17 0 9 0 9Z"
                                        fill="#525B73" />
                                    <path
                                        d="M8 9C8 8.92239 8.00295 8.84547 8.00873 8.76935L12.0827 11.7987C11.7468 11.9287 11.3817 12 11 12C9.34315 12 8 10.6569 8 9Z"
                                        fill="#525B73" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>