

<!-- <p-menu [model]="menuItems" [style]="{'width': '100%', 'height': '100%'}"></p-menu> -->
<!-- <p-menubar [model]="menuItems" *ngIf="window.location.pathname !== '/login'" styleClass="sidebarBody" id="sidebarBody"></p-menubar> -->

<!-- <div class="grid">
      <ng-container *ngIf="window.location.pathname === '/login' || window.location.pathname === '/register'; else dsMenu"></ng-container>
      <ng-template #dsMenu>
            <div class="col-9"style="padding: 0;">
                  <p-megaMenu [model]="menuItems" *ngIf="window.location.pathname !== '/login'" styleClass="sidebarBody"></p-megaMenu>
            </div>
            <div class="col-3" style="padding: 0">
             <p-megaMenu  [model]="menuRights" *ngIf="window.location.pathname !== '/login'" styleClass="sidebarBody1"></p-megaMenu>
         </div>
      </ng-template>
</div> -->

<div class="grid bg-white">
      <div class="col-9"style="padding: 0;">
            <p-megaMenu [model]="menuItems" *ngIf="!((window.location.pathname === '/login') || (window.location.pathname === '/register'))" styleClass="sidebarBody"></p-megaMenu>
      </div>
      <div class="col-3" style="padding: 0">
       <p-megaMenu  [model]="menuRights" *ngIf="!((window.location.pathname === '/login') || (window.location.pathname === '/register'))" styleClass="sidebarBody1"></p-megaMenu>
   </div>
</div>