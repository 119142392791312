import { Component, OnInit } from '@angular/core';
import {Event, RouterEvent, Router} from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-layout-login',
  templateUrl: './layout-login.component.html',
  styleUrls: ['./layout-login.component.scss']
})
export class LayoutLoginComponent implements OnInit {

  hideHeader = false;
  constructor(
    private router: Router,
  ) {
    router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
   ).subscribe((e) => {
      if(e.url.includes('/login') || e.url.includes('/register') || e.url.includes('/forgot-password')) {
        this.hideHeader = true;
      }else{
        this.hideHeader = false;
      }
   });
  }

  ngOnInit(): void {
    const pathname = window.location.pathname;
    if(pathname === '/login' || pathname === '/register' || pathname === '/forgot-password') {
      this.hideHeader = true;
    }else{
      this.hideHeader = false;
    }
  }

}
