import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { ApiSmartService } from 'src/app/services/api-smart/apismart.service';
import { AuthService } from 'src/app/services/auth.service';
import { chunk, cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';
import { AUTH_CONSTANT } from 'src/app/common/constants/auth.constanst';
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    menuItems: any[] = [];
    @ViewChild('megaMenu', {static: true}) $megaMenu: ElementRef;
    window = window
    constructor(
        private router: Router,
        private authService: AuthService,
        private apiSmart: ApiSmartService,
    ) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            const pathname = window.location.pathname;
            if (this.menuItems.length > 0) {
                this.parseObjectProperties2(this.menuItems, event.url);
                this.menuItems = [...this.menuItems];
                localStorage.setItem(AUTH_CONSTANT.MENU_ITEMS, JSON.stringify(this.menuItems));
            } else {
                if (this.authService.getIdToken()) {
                    this.getListMenuByUserId(pathname)
                }
            }
        });
    }


    ngOnInit() {
    }
    menuRights = [];
    getListMenuByUserId(pathname) {
        this.apiSmart.getUserMenus().subscribe(results => {
            this.menuItems = results.data.filter(menuItem => menuItem.menuId !== 332 && menuItem.menuId !== 409);
            const menuRights =results.data.filter(menuItem => menuItem.menuId === 332 || menuItem.menuId === 409);
            const posMenu = menuRights.find(t => t.menuId === 332);
            if (posMenu) {
                posMenu.command = () => {
                    window.open(environment.POS_PROD,'_blank');
                }
            }
            this.menuRights = menuRights;
            localStorage.setItem(AUTH_CONSTANT.MENU_ITEMS, JSON.stringify(results.data));
            this.parseObjectProperties(this.menuRights, pathname);
            this.parseObjectProperties2(this.menuItems, pathname);
            this.menuItems = [...this.menuItems];
            this.menuRights = [...this.menuRights];
        })
    }


    parseObjectProperties2(obj: any[], pathname) {
        for (let k of obj) {
            k.label = k.title;
            if (k.path && k.class !== 'navigation-header') {
                k.routerLink = k.path
            } else {
                if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.class === 'navigation-header') {
                    k.styleClass = k.class + " parent_active"
                } else {
                    k.styleClass = k.class + " parent_no_active"
                }
            }
            // if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.class === 'navigation-header') {
            //     k.styleClass = k.class + " parent_active"
            // } else {
            //     k.styleClass = k.class + " parent_no_active"
            // }
            if (k.submenus && k.submenus.length > 0) {
                let items = k.submenus.filter((d: any) => d.class && (d.class.indexOf("hidden") < 0));
                items = items.map(d => {
                    d.label = d.title;
                    d.icon = d.icon;
                    d.routerLink = d.path;
                    d.styleClass = d.class;
                    // if (k.routerLink && pathname === k.routerLink) {
                    //     k.styleClass = k.class + ' active'
                    // } else {
                    //     k.styleClass = k.class + ' no-active';
                    // }
                    return d
                });

                const chunkSize = items.length > 6 ?  items.length/ 6: 10;
                const pusshddd = []
                for (let i = 0; i < items.length; i += chunkSize) {
                    const chunk = items.slice(i, i + chunkSize);
                    pusshddd.push({
                        items: chunk
                    })
                }
                k.items = [
                    ...chunk(pusshddd, 2)
                ]

            } else {
                k.items = []
            }


            // if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
            //     this.parseObjectProperties(k.items, pathname);
            // }

            // if (k.routerLink) {
            //     // active menu con
            //     if (k.routerLink && pathname === k.routerLink) {
            //         k.styleClass = k.class + ' active'
            //         k.icon = ''
            //     } else {
            //         k.styleClass = k.class + ' no-active';
            //         k.icon = ''
            //     }
            // } else {
            //     //active cha
            //     if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.class === 'navigation-header') {
            //         k.styleClass = k.class + " parent_active"
            //     } else {
            //         k.styleClass = k.class + " parent_no_active"
            //     }
            // }

        }
    }



    parseObjectProperties(obj: any[], pathname) {
        for (let k of obj) {
            k.label = k.title;
            if (k.path && k.class !== 'navigation-header') {
                k.routerLink = k.path
            }
            if (k.routerLink) {
                // active menu con
                if (k.routerLink && pathname === k.routerLink) {
                    k.styleClass = k.class + ' active'
                } else {
                    k.styleClass = k.class + ' no-active';
                }
            } else {
                //active cha
                if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.class === 'navigation-header') {
                    k.styleClass = k.class + " parent_active"
                } else {
                    k.styleClass = k.class + " parent_no_active"
                }
            }

            if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
                this.parseObjectProperties(k.items, pathname);
            }
        }
    }


}
