import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptor } from './services/auth-interceptor';
import { AuthService } from './services/auth.service';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { FeedBaseService } from './services/firebase.service';
import { NotificationService } from './services/notification.service';
import { SharedModule } from './shared/shared.module';
import { SunshineComponent } from './sunshine/sunshine.component';
import { CurrencyDirectiveModule } from './utils/common/currency.module';
// 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { HealthComponent } from './components/health/health.component';
import { DefaultLayoutComponent } from './containers/default-layout';
import { DefaultLayoutCreateOrderComponent } from './containers/default-layout-create-order/default-layout-create-order';
import { DefaultLayoutCreatePurchaseOrderComponent } from './containers/default-layout-create-purchase-order/default-layout-create-purchase-order';
import { DefaultLayoutCustomerComponent } from './containers/default-layout-customer-info/default-layout-customer-info';
import { DefaultLayoutPosComponent } from './containers/default-layout-pos/default-layout-pos.component';
import { LayoutLoginComponent } from './containers/layout-login/layout-login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ApiCoreService } from './services/api-core/apicore.service';
import { ApiSmartService } from './services/api-smart/apismart.service';
import { ApiService } from './services/api.service';
import { ExportFileService } from './services/export-file.service';
import { NavbarService } from './services/navbar.service';
import { ExcelComponent } from './shared/components/excel/excel.component';
import { CheckboxEditorComponent } from './utils/common/checkbox-editor.component';
import { CustomTooltipComponent } from './utils/common/customtooltip.component';
import { DropdownRendererComponent } from './utils/common/dropdown-renderer.component';
import { NumberCellRenderer } from './utils/common/number-renderer.component';
import { TextEditorComponent } from './utils/common/text-editor.component';
import { TooltipSuggestionComponent } from './utils/common/tooltip-suggestion.component';
import { ListGridAngularModule } from './common/list-grid-angular/list-grid-angular.module';
const APP_CONTAINERS = [
    DefaultLayoutComponent,
    DefaultLayoutPosComponent,
    DefaultLayoutCreateOrderComponent,
    DefaultLayoutCreatePurchaseOrderComponent,
    LayoutLoginComponent,
    DefaultLayoutCustomerComponent];
@NgModule({
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        SunshineComponent,
        NumberCellRenderer,
        TextEditorComponent,
        CheckboxEditorComponent,
        CustomTooltipComponent,
        TooltipSuggestionComponent,
        DropdownRendererComponent,
        LoginComponent,
        RegisterComponent,
        HomeComponent,
        ForgotPasswordComponent,
        HealthComponent
    ],
    imports: [
        BrowserAnimationsModule,
        ListGridAngularModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        ButtonModule,
        DialogModule,
        CurrencyDirectiveModule,
        ConfirmDialogModule,
        SplitButtonModule,
        ScrollPanelModule,
        NgxSpinnerModule,
        ToastModule,
        ChartModule,
        TabMenuModule,
        DropdownModule,
        CalendarModule,
        AutoCompleteModule,
        CardModule,
        TimelineModule,
        CheckboxModule,
        TabViewModule,
    ],
    entryComponents: [
        ExcelComponent
    ],
    providers: [
        AuthService,
        AuthGuardService,
        FirebaseAuthService,
        ExportFileService,
        ApiService,
        ApiSmartService,
        NavbarService,
        ApiCoreService,
        FeedBaseService,
        NotificationService,
        ConfirmationService,
        MessageService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
