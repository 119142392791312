import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ApiSmartService } from 'src/app/services/api-smart/apismart.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-thong-tin-ca-nhan',
  templateUrl: './thong-tin-ca-nhan.component.html',
  styleUrls: ['./thong-tin-ca-nhan.component.scss']
})
export class ThongTinCaNhanComponent implements OnInit {

  items = [];
  action = 'UserInfo';
  user = {
    avatar: null,
    email: null,
    id: null,
    loginName: null,
    name: null,
    phone: null,
    role: null,
    userId: null,
    stores: null
  };
  changePassword = {
    loginName: null,
    oldPassword: null,
    newPassword: null,
    repeatPassword: null
  };
  isShowPass = false;
  isShowPassNew = false;
  isShowPassConfirm = false;
  imgUrl: any = "/assets/images/no-image2.jpg"; 
  constructor(
    private _service: ApiSmartService,
    private _authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private apiSmartService: ApiSmartService,
    private spinner: NgxSpinnerService,
  ) { }
  menus: any = [];
  ngOnInit(): void {
    this.initBreadcrumb();
    this.getUserInfo();
    
  }
  initBreadcrumb() {
    this.items = [
      { label: 'Trang chủ', routerLink: '/home' },
      { label: 'Thông tin của tôi' }
    ];

    this.menus = [
      {
        label: 'Thông tin tài khoản',
        command: (e) => {
          this.action = 'UserInfo';
        }
      },
      // {
      //   label: 'Chỉnh sửa thông tin tài khoản',
      //   command: (e) => {
      //     this.action = 'UserUpdate';
      //   }
      // },
      {
        label: 'Đổi mật khẩu',
        command: (e) => {
          this.action = 'ChangePassword'
        }
      }
    ];
  }

  getUserInfo() {
    let userName = this._authService.getUserName();
    this._service.getUserInfoByUserName(userName).subscribe(
      res => {
        this.user = res.data;
        this.imgUrl = this.user.avatar;
        this.changePassword.loginName = res.data.loginName;
      }
    )
  }

  saveData() {
    const dataSave = {...this.user, avatar: this.imgUrl}
    this._service.setUpdateProfile(dataSave).subscribe((results)=> {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Cập nhật thông tin tài khoản thành công' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Cập nhật thông tin tài khoản thất bại' });
      }
    })
    // switch (this.action) {
    //   case 'ChangePassword':
    //     this.SavePassword();
    //     return;
    // }
  }

  CancerData() {
    this.router.navigateByUrl('/home');
  }

  onUploadOutput(event) {
    this.spinner.show();
    if (event.target.files[0] && event.target.files[0].size > 0) {
      const formData = new FormData();
      formData.append('File', event.target.files[0]);
      formData.append('CustomerAttribute', '');
      this.apiSmartService.uploadFile(formData).subscribe(results => {
        if (results.status === 'success') {
          this.imgUrl = results.data;
        }
      })
      this.spinner.hide();
    }
  }

  SavePassword() {
    if (this.changePassword.newPassword !== this.changePassword.repeatPassword) {
      this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Mật khẩu mới không khớp' });

      return;
    }

    if (this.changePassword.oldPassword == '' || this.changePassword.oldPassword == null
      || this.changePassword.newPassword == '' || this.changePassword.newPassword == null
      || this.changePassword.repeatPassword == '' || this.changePassword.repeatPassword == null) {
      this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Mật khẩu không được để trống' });
      return;
    }
    let request = {
      userName: this.changePassword.loginName,
      oldPassword: this.changePassword.oldPassword,
      newPassword: this.changePassword.newPassword
    };
    this._service.changePassword(request).subscribe(
      response => {
        if (response.statusCode == 1) {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Cập nhật mật khẩu thành công' });
          return;
        }

        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: response.error[0] });

      }
    )
    return;

  }



}
