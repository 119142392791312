import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    name = '';
    avatarUrl = '';
    items: MenuItem[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        // private themeService: ThemeService
    ) {
        this.items = [{
            label: 'Thông tin của tôi',
            icon: 'pi pi-user',
            command: () => {
                this.userProfile();
            }
        }, {
            label: 'Đăng xuất',
            icon: 'pi pi-refresh',
            command: () => {
                this.logout();
            }
        }];
        
    }

    logout() {
        this.authService.logout();
        this.router.navigate(["/login"], { replaceUrl: true });
    }

    ngOnInit() {
        this.name = this.authService.getName();
    }

    update() {

    }

    delete() {

    }

    userProfile(){
        this.router.navigate(["/manager/thong-tin-ca-nhan"])
    }

    // changeTheme(theme: string) {
    //     this.themeService.switchTheme(theme);
    // }

}
