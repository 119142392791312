<div class="modal-filter-container" [ngStyle]="styleCss">
    <div class="header">
        Bộ lọc nâng cao
        <svg (click)="closeModalFilter()" width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4.43627 4.43627C4.79597 4.07658 5.37915 4.07658 5.73884 4.43627L9.99984 8.69727L14.2608 4.43627C14.6205 4.07658 15.2037 4.07658 15.5634 4.43627C15.9231 4.79597 15.9231 5.37915 15.5634 5.73884L11.3024 9.99984L15.5634 14.2608C15.9231 14.6205 15.9231 15.2037 15.5634 15.5634C15.2037 15.9231 14.6205 15.9231 14.2608 15.5634L9.99984 11.3024L5.73884 15.5634C5.37915 15.9231 4.79597 15.9231 4.43627 15.5634C4.07658 15.2037 4.07658 14.6205 4.43627 14.2608L8.69727 9.99984L4.43627 5.73884C4.07658 5.37915 4.07658 4.79597 4.43627 4.43627Z"
                fill="black" />
        </svg>

    </div>
    <div class="body">
        <ng-content select=".body-container"> </ng-content>
    </div>
    <div class="footer">
        <ng-content></ng-content>
    </div>
</div>