import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment.dev-k8s';
const queryString = require('query-string');
declare var google: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  constructor(
    private router: Router,
    private apiService: ApiService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private authen: AuthService
  ) {
  }
  
  selectedCountry: any = '';
  isShowPass = false
  countries = [
    { label: '+84', value: 'VN' },
  ];
  displayMessageSuccess = false
  counter = 30;
  geos = [];
  getValue = '';
  listCities = [];
  isSent = false;
  isUseTemplate = true;
  get f(): any { return this.createDetailForm.controls; }
  listPackages = [];
  listFields = [];
  createDetailForm = null;
  messageSuccess = ''
  ngOnInit(): void {
    this.createFormBuilder()
    this.getPagingPackage()
    this.getcities()
    this.initGoogle()
    this.authen.logout();
  }


  getcities() {
    this.apiService.getcities().subscribe((results: any) => {
      if (results.status === 'success') {
        this.listCities = results.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
        this.createDetailForm.get('city_id').setValue(this.listCities[0].value);
      }
    })


  }
  getPagingPackage() {
    const queryParams = queryString.stringify({ offSet: 0, pageSize: 10 });
    this.apiService.getPagingPackage(queryParams).subscribe((results: any) => {
      if (results.status === 'success') {
        this.listPackages = results.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        });
        this._route.queryParams.subscribe(prms => {
          let packageCode = prms['package'];
          if (packageCode !== null && packageCode !== undefined) {
            let selectedPackage = results.data.filter(c => c.code == packageCode)[0].id;
            this.createDetailForm.get('package_id').setValue(selectedPackage);
          } else {
            this.createDetailForm.get('package_id').setValue(results.data[0].id)
          }
        })
      }
    })
  }

  
  createFormBuilder() {
    this.createDetailForm = this.formBuilder.group({
      id: new FormControl(null),
      package_id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      store_name: new FormControl(null),
      city_id: new FormControl(null),
      username: new FormControl(null, [Validators.required]),
      customer_code: new FormControl(null),
      email: new FormControl(null, [Validators.required]),
      otp: new FormControl(null, [Validators.required]),
      isUseTemplate: new FormControl(true)
    });

    for (let key in this.createDetailForm.value) {
      this.listFields.push(key)
    }
  }

  sendOTP() {
    let phone = this.getPhoneNumber(this.createDetailForm.value.phone);
    let request = {
      phone: phone,
      otp: '',
      email: ''
    };
    if (phone == '' || phone == undefined) {
      this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Số điện thoại không được để trống.' });
      return;
    }
    this.apiService.sendOTP(request).subscribe((results: any) => {
      if (results.statusCode === 1) {
        this.isSent = true;
        this.startCountdown();
      }
    })
  }

  sendOTPEmail() {
    let email = this.createDetailForm.value.email
    let request = {
      email: email,
      otp: '',
      phone: ''
    };
    if (email == '' || email == undefined) {
      this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Email không được để trống.' });
      return;
    }
    this.apiService.sendOTPEmail(request).subscribe((results: any) => {
      if (results.statusCode === 1) {
        this.isSent = true;
        this.startCountdown();
      }
    })
  }

  saveRegister() {
    if (!this.validate()) {
      return;
    }
    this.createDetailForm.get('username').setValue(this.getPhoneNumber(this.createDetailForm.get('phone').value));
    const params = {
      ...this.createDetailForm.value
    }
    params.email = ''
    params.phone = '0' + params.phone
    this.createDetailForm.get('isUseTemplate').setValue(this.isUseTemplate);
    this.apiService.addCustomer(params).subscribe((results: any) => {
      if (results.status === 'success') {
        this.displayMessageSuccess = true;
        this.messageSuccess = 'Bạn đã đăng ký thành công! Tên đăng nhập và Mật khẩu sẽ được gửi đến Số điện thoại của bạn!'
        // this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Tạo tại khoản thành công' });
        // this.router.navigate(['/login'])
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.error.length > 0 ? results.error[0] : '' });
      }
    })
  }

  changeDisplayMessageSuccess() {
    this.displayMessageSuccess = false;
    if (!this.displayMessageSuccess) {
      this.router.navigate(['/login'])
    }
  }

  saveRegisterEmail() {
    if (!this.validateEmail()) {
      return;
    }
    this.createDetailForm.get('email').setValue(this.createDetailForm.value.email);
    const params = {
      ...this.createDetailForm.value
    }
    params.username = '';
    params.phone = ''
    this.createDetailForm.get('isUseTemplate').setValue(this.isUseTemplate);
    this.apiService.addCustomerEmail(params).subscribe((results: any) => {
      if (results.status === 'success') {
        this.displayMessageSuccess = true;
        this.messageSuccess = 'Bạn đã đăng ký thành công! Tên đăng nhập và Mật khẩu sẽ được gửi đến Email của bạn!'
        // this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Tạo tại khoản thành công' });
        // this.router.navigate(['/login'])
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.error.length > 0 ? results.error[0] : '' });
      }
    })
  }

  initGoogle() {
    google.accounts.id.initialize({
      client_id: environment.GOOGLE_CLIENT_ID,
      callback: this.handleGoogleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("googleLogin"),
      { theme: "outline", size: "large" }
    );
    google.accounts.id.prompt();
  }

  handleGoogleCredentialResponse(response: any) {
    console.log(response);
  }

  startCountdown() {
    this.counter = 90;
    const interval = setInterval(() => {
      this.counter--;
      if (this.counter == 0) {
        this.isSent = false;
        clearInterval(interval);
      }
    }, 1000)
  }

  getPhoneNumber(phone) {
    if (phone == null || phone == undefined || phone == '') {
      return '';
    }
    return `0${phone}`;
  }

  validateEmail() {
    if (this.createDetailForm.get('name').value == null || this.createDetailForm.get('name').value == ''
      || this.createDetailForm.get('store_name').value == null || this.createDetailForm.get('store_name').value == ''
      || this.createDetailForm.get('email').value == null || this.createDetailForm.get('email').value == ''
      || this.createDetailForm.get('otp').value == null || this.createDetailForm.get('otp').value == ''

    ) {
      this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Vui lòng điền đẩy đủ thông tin.' });
      return false;
    }

    // if (!this.isSent) {
    //   this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'OTP đã hết hạn.' });
    //   return false;
    // }

    return true;
  }

  validate() {
    if (this.createDetailForm.get('name').value == null || this.createDetailForm.get('name').value == ''
      || this.createDetailForm.get('phone').value == null || this.createDetailForm.get('phone').value == ''
      || this.createDetailForm.get('store_name').value == null || this.createDetailForm.get('store_name').value == ''
      || this.createDetailForm.get('otp').value == null || this.createDetailForm.get('otp').value == ''

    ) {
      this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Vui lòng điền đẩy đủ thông tin.' });
      return false;
    }
    return true;
  }

}
