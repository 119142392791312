import * as numeral from "numeral";

export function AgGridFn(lists: Array<any>) {
    return lists.map(value => {
        let row: any = {
            headerName: value.columnCaption,
            field: value.columnField,
            filter: value.isFilter ? 'agTextColumnFilter' : '',
            sortable: false,
            width: value.columnWidth,
            hide: value.isHide ? true : false,
            pinned: value.pinned,
            headerTooltip: value.columnCaption,
            tooltipField: value.columnField,
            id: value.id,
            viewGrid: value.view_grid,
            ordinal: value.ordinal,
            isHide: value.isHide ? true : false
        };
        
        if (value.isStatusLable) {
            row.cellClass = value.cellClass;
            row.cellRenderer = (params: any) => {
                return `<span class="noti-number noti-number-on ml5">${params.value}</span>`
            };
        } else if (value.columnField === 'avatarUrl' || value.fieldType === 'image') {
            row.cellRenderer = "avatarRendererFull";
            row.cellClass = ['text-center', 'text-right', 'border-right', 'd-flex', 'align-items-center', 'justify-content-center'];
        } else if (value.fieldType === 'check') {
            row.cellClass = value.cellClass;
            row.cellRenderer = (params: any) => {
                return `<span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
                <input type="checkbox" ${params.value ? 'checked' : ''} disabled class="custom-control-input"  >
                <label class="custom-control-label"></label>
              </span>`;
            };
        } else if (value.fieldType === 'decimal') {
            row.cellClass = value.cellClass || [];
            row.headerClass = value.headerClass;
            row.cellStyle = value.cellStyle;
            row.cellClassRules = value.conditionClass;
            row.cellRenderer = value.isMasterDetail ? 'agGroupCellRenderer' : '';
            row.aggFunc = 'sum';
            row.valueFormatter = value.customFormat ? formatMargin : formatNumber2;
        } else {
            row.cellClass = value.columnField === 'bar_code' || value.columnField === 'code' || value.columnField === 'date' || value.columnField === 'internal_code' ?  value.cellClass && value.cellClass.length > 0 ? ['stringType', ...value.cellClass] : [] : value.cellClass;
            row.cellRenderer = value.isMasterDetail ? 'agGroupCellRenderer' : '';
        }
        return row;
    });
}

export function stringtodate(datestring) {
    if (datestring) {
        const parts = datestring.split('/');
        const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
        return mydate;
    } else {
        return new Date();
    }
}

export function formatNumber2(params) {
    const number = params.value;
    return numeral(number).format('0,0[.][000]')
}

export function formatNumber3(value) {
    return numeral(value).format('0,0[.][000]')
}

export function formatMargin(params) {
    const numb = +params.value;
    return (numb * 100).toFixed(2);
}

export function ShowFullScreen() {
    const c: any = document.querySelector(".breadcrumb");
    const d: any = document.querySelector(".filterInput");
    const e: any = document.querySelector(".paginator");
    const totalHeight = c.clientHeight + d.clientHeight + e.clientHeight + 24;
    const main: any = document.querySelector(".main");
    main.className = main.className + ' grid-fixed';
    return window.innerHeight - totalHeight
}

export function HideFullScreen() {
    const main: any = document.querySelector(".main");
    main.className = 'main';
    const a: any = document.querySelector(".header");
    const b: any = document.querySelector(".sidebarBody");
    const c: any = document.querySelector(".breadcrumb");
    const d: any = document.querySelector(".filterInput");
    const e: any = document.querySelector(".paginator");
    const totalHeight = a.clientHeight + b.clientHeight + c.clientHeight + d.clientHeight + e.clientHeight + 45;
    return window.innerHeight - totalHeight
}

export function CheckHideAction(path, action) {
    return false;
    // const menuItems = localStorage.hasOwnProperty('menuItems') ? JSON.parse(localStorage.getItem('menuItems')) : null;
    // if (menuItems && menuItems.length > 0) {
    //     let newArray = []
    //     menuItems.forEach(element => {
    //         newArray.push(element);
    //         if (element.submenus && element.submenus.length > 0) {
    //             element.submenus.forEach(element1 => {
    //                 newArray.push(element1);
    //             });
    //         }
    //     });
    //     if (newArray.length > 0) {
    //         const menus = newArray.find(m => m.path === path);
    //         if (menus && menus.actions.length > 0) {
    //             const arrAction = menus.actions.map(d => d.actionCd);
    //             if (arrAction.indexOf(action) > -1) {
    //                 return  false;
    //             } else {
    //                 return true;
    //             }
    //         }else {
    //             return true;
    //         }
    //     }else {
    //         return true;
    //     }

    // }else {
    //     return true;
    // }
}

export function SumArray(mang){
    let sum = 0;
    let i = 0;
    while (i < mang.length){
        sum += mang[i];
        i++;
    }
    return sum;
}


export function TextFormatter(r){
    if (r == null) return null;
    return r
      .toLowerCase()
      .replace(/[àáâãäå]/g, 'a')
      .replace(/æ/g, 'ae')
      .replace(/ç/g, 'c')
      .replace(/[èéêë]/g, 'e')
      .replace(/[ìíîï]/g, 'i')
      .replace(/ñ/g, 'n')
      .replace(/[òóôõö]/g, 'o')
      .replace(/œ/g, 'oe')
      .replace(/[ùúûü]/g, 'u')
      .replace(/[ýÿ]/g, 'y');
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }