<div class="menu dashboard">
    <div class="menu-field">
        <div class="input-group">
            <label>Siêu thị</label>
            <p-dropdown [options]="stores" [(ngModel)]="store_id" [autoDisplayFirst]="false"
                            (onChange)="changeStore($event)" [filter]="true">
                            <ng-template let-item pTemplate="selectedItem">
                                <span style="vertical-align:middle; ">{{item.label}}</span>
                            </ng-template>
                            <ng-template let-car pTemplate="item">
                                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                                </div>
                            </ng-template>
                            </p-dropdown>
        </div>
        <div class="input-group">
            <label>Tên ngành</label>
            <p-dropdown [options]="categoryOptions" [(ngModel)]="categorySelected"></p-dropdown>
        </div>
        <div class="input-group">
            <label>Ngày</label>
            <div class="double-calendar">
                <div class="calendar-wrapper">
                  <p-calendar [readonlyInput]="true" [(ngModel)]="fromDate" [maxDate]="toDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050" placeholder="Từ ngày" [showButtonBar]="true" dateFormat="dd/mm/yy" (ngModelChange)="detectChange('fromDate', $event)"></p-calendar>
                  <span style="display: flex; align-items: center; margin-right: 10px;"><i class="pi pi-arrow-right"></i></span>
                  <p-calendar [readonlyInput]="true" [(ngModel)]="toDate" [minDate]="fromDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050" placeholder="Đến ngày" [showButtonBar]="true" dateFormat="dd/mm/yy" (ngModelChange)="detectChange('toDate', $event)"></p-calendar>
                  <span style="display: flex; align-items: center; margin-right: 12px"><i class="pi pi-calendar"></i></span>
                </div>
            </div>
        </div>
        <div class="menu-option">
            <p-button (click)="reset()" styleClass="p-button-outlined" label="Reset"></p-button>
            <p-button (click)="find()" label="Lọc"></p-button>
        </div>
    </div>
</div>
<div class="home">
    <div class="grid">
        <div class="col-9">
            <div class="boxs">
                <div class="col-4">
                    <div class="box">
                        <div class="desc">
                            <h5>Doanh thu</h5>
                            <div class="quan">
                                <span class="number">
                                    {{(summaryDataModel?.revenue.amount ? summaryDataModel?.revenue.amount : 0) | number}} VND
                                </span>
                                <div>
                                    <span class="percen">
                                        +{{summaryDataModel?.revenue.margin ? summaryDataModel?.revenue.margin : 0}}%
                                    </span>
                                    <div class="note">so với năm {{yearAgo}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="box">
                        <div class="desc">
                            <h5>Lợi nhuận</h5>
                            <div class="quan">
                                <span class="number">
                                    {{(summaryDataModel?.profit ? summaryDataModel?.profit : 0) | number}} VND
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="box">
                        <div class="desc">
                            <h5>% Lợi nhuận</h5>
                            <div class="quan">
                                <span class="number">
                                    {{summaryDataModel?.profitMargin ? summaryDataModel?.profitMargin : 0}}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="area grid">
                <div class="area-wrapper col-4">
                    <div class="area-1-item section">
                        <div class="flex">
                            <h3 class="title">Doanh thu theo ngày</h3>
                            <span class="ml-1" style="color: red; font-size: 12px; padding-top: 5px;">
                                <i>*</i>Hiển thị tối đa 31 ngày
                            </span>
                        </div>
                        <div class="the-chart">
                            <p-chart type="line" [data]="revenueChart?.data" [options]="revenueChart?.options" height="350"></p-chart>
                        </div> 
                    </div>
                </div>
                <div  class="area-wrapper col-8">
                    <div class="area-item section">
                        <h3 class="title">Doanh thu theo tháng</h3>
                        <div class="the-chart">
                            <p-chart type="bar" [data]="monthlyRevenneChart?.data" [options]="monthlyRevenneChart?.options" height="350"></p-chart>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="area grid">
                <div class="area-wrapper col-6">
                    <div class="area-item section">
                        <h3 class="title">Doanh thu theo ngành hàng</h3>
                        <div class="content">
                            <div class="the-chart">
                                <p-chart type="bar" [data]="revenueByCategory?.data" height="365" [options]="revenueByCategory?.options"></p-chart>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="area-wrapper col-6">
                    <div class="area-item section">
                        <h3 class="title">Top 20 Sản phẩm bán chạy</h3>
                        <div class="time">
                            <p-tabMenu class="menu-timeline menu-tab" [model]="top20SellingTabMenuItems" [activeItem]="top20SellingTabMenuItems[0]"></p-tabMenu>
                        </div>
                        <div class="content">
                            <div class="the-chart">
                                <p-chart type="bar" [data]="bestSellingChart?.data" height="338" [options]="bestSellingChart?.options"></p-chart>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="grid">
                <div class="col-12">
                    <div class="section activites">
                        <h3 class="title">Hoạt động</h3>
                        <div class="content">
                            <p-timeline [value]="timelines" align="left" class="timeline-default">
                                <ng-template pTemplate="content" let-event>
                                    <p-card [header]="event.status" [subheader]="event.date">
                                        <div class="timeline-item">
                                            <span class="main-color">{{event.employeeName}}</span> vừa <span class="main-color">{{event.action}}</span> với giá trị {{event.value}} VND
                                            <i>{{event.time}}</i>
                                        </div>
                                    </p-card>
                                </ng-template>
                            </p-timeline>
                            <div class="text-center">
                                <svg class="line" width="230" height="1" viewBox="0 0 230 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="230" height="1" fill="#DDEFFF"/>
                                </svg>
                                <span class="readmore">
                                    Xem thêm
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="section">
                        <h3 class="title">Tỷ trọng doanh thu</h3>
                        <div class="time">
                            <p-tabMenu class="menu-timeline menu-tab" [model]="revenuePieMenuItems" [activeItem]="revenuePieMenuItems[0]"></p-tabMenu>
                        </div>
                        <div class="content" style="margin-top: 5px;">
                            <div class="the-chart">
                                <p-chart type="pie" [data]="pieData?.data" [options]="pieData?.options" height="335" ></p-chart>
                            </div> 
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12">
                    <div class="section document">
                        <h3 class="title text-left">Hướng dẫn sử dụng</h3>
                        <svg width="266" height="202" viewBox="0 0 266 202" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.1045 0.246033H237.895C240.011 0.246033 242.041 1.01154 243.537 2.37414C245.034 3.73675 245.874 5.58483 245.874 7.51185V194.491C245.874 196.418 245.034 198.266 243.537 199.628C242.041 200.991 240.011 201.756 237.895 201.756H28.1045C27.0566 201.756 26.019 201.569 25.0509 201.203C24.0828 200.838 23.2032 200.303 22.4623 199.628C21.7213 198.954 21.1336 198.153 20.7326 197.271C20.3316 196.39 20.1252 195.445 20.1252 194.491V7.51197C20.1252 6.55779 20.3316 5.61296 20.7326 4.73141C21.1336 3.84987 21.7213 3.04888 22.4623 2.37417C23.2032 1.69947 24.0828 1.16427 25.0509 0.799118C26.019 0.433971 27.0566 0.246033 28.1045 0.246033Z" fill="#F3F9FF"/>
                            <path d="M40.6463 0.24353H20.1252C18.5672 2.06707 17.1656 3.70851 15.8563 5.24057L12.1481 9.5815L8.62676 13.7035L4.91854 18.0443L0.649658 23.0415H24.7115L28.2043 18.0443L31.2384 13.7035L34.1195 9.5815L37.1536 5.24057C38.2245 3.70892 39.3716 2.06707 40.6463 0.24353Z" fill="#FF7C59"/>
                            <path d="M61.1676 0.246033H40.6456C39.3709 2.06957 38.2237 3.71101 37.1528 5.24307L34.1187 9.584L31.2376 13.706L28.2035 18.0468L24.7108 23.044H48.7738C49.7651 21.2203 50.6575 19.5789 51.4903 18.0468C52.3232 16.5148 53.0965 15.0922 53.8503 13.706L56.0911 9.584L58.4509 5.24307C59.2838 3.71142 60.1762 2.06957 61.1676 0.246033Z" fill="#FFA384"/>
                            <path d="M81.689 0.246033H61.1663C60.1749 2.06957 59.2824 3.71101 58.4496 5.24307L56.0898 9.584L53.8489 13.706C53.0952 15.0922 52.3218 16.5148 51.489 18.0468C50.6561 19.5789 49.7637 21.2203 48.7724 23.044H72.8361C73.7213 20.7644 74.496 18.7696 75.2152 16.9172C75.9344 15.0647 76.5981 13.3549 77.2625 11.645C77.927 9.93513 78.5905 8.22509 79.3099 6.37276L81.689 0.246033Z" fill="#FF7C59"/>
                            <path d="M102.213 0.246033H81.689L79.3099 6.37276C78.5905 8.22509 77.927 9.93513 77.2626 11.645C76.5981 13.3549 75.9346 15.065 75.2152 16.9172C74.4959 18.7693 73.7213 20.7644 72.8361 23.044H96.9011L98.3284 16.9172C98.7601 15.065 99.1586 13.3549 99.5569 11.645C99.9552 9.93513 100.354 8.22509 100.785 6.37276C101.216 4.52043 101.681 2.52567 102.213 0.246033Z" fill="#FFA384"/>
                            <path d="M122.737 0.246033H102.214C101.682 2.52567 101.218 4.52043 100.786 6.37276C100.354 8.22509 99.956 9.93513 99.5581 11.645C99.1602 13.3549 98.7613 15.065 98.3296 16.9172L96.9023 23.044H120.967C121.144 20.7644 121.299 18.7696 121.443 16.9172C121.587 15.0647 121.72 13.3549 121.853 11.645C121.985 9.93513 122.118 8.22509 122.262 6.37276C122.406 4.52043 122.56 2.52567 122.737 0.246033Z" fill="#FF7C59"/>
                            <path d="M143.261 0.246033H122.737C122.56 2.52567 122.405 4.52043 122.261 6.37276C122.117 8.22509 121.984 9.93513 121.852 11.645C121.719 13.3549 121.586 15.065 121.442 16.9172C121.298 18.7693 121.143 20.7644 120.966 23.044H145.031C144.854 20.7644 144.699 18.7696 144.556 16.9172C144.412 15.0647 144.279 13.3549 144.146 11.645C144.013 9.93513 143.881 8.22509 143.737 6.37276C143.593 4.52043 143.438 2.52567 143.261 0.246033Z" fill="#FFA384"/>
                            <path d="M163.784 0.246033H143.26C143.437 2.52567 143.592 4.52043 143.736 6.37276C143.88 8.22509 144.013 9.93513 144.146 11.645C144.279 13.3549 144.411 15.065 144.555 16.9172C144.699 18.7693 144.854 20.7644 145.031 23.044H169.096C168.565 20.7644 168.101 18.7696 167.668 16.9172C167.236 15.0647 166.839 13.3549 166.441 11.645L165.213 6.37276C164.78 4.52043 164.315 2.52567 163.784 0.246033Z" fill="#FF7C59"/>
                            <path d="M184.307 0.246033H163.784C164.315 2.52567 164.78 4.52043 165.211 6.37276L166.439 11.645C166.837 13.3549 167.236 15.065 167.667 16.9172C168.098 18.7693 168.563 20.7644 169.094 23.044H193.159C192.274 20.7644 191.499 18.7696 190.78 16.9172C190.061 15.0647 189.397 13.3549 188.733 11.645C188.069 9.93513 187.405 8.22509 186.686 6.37276L184.307 0.246033Z" fill="#FFA384"/>
                            <path d="M204.83 0.246033H184.307L186.686 6.37276C187.406 8.22509 188.069 9.93513 188.734 11.645C189.398 13.3549 190.062 15.065 190.781 16.9172C191.5 18.7693 192.275 20.7644 193.16 23.044H217.224L214.507 18.0468C213.674 16.5148 212.901 15.0922 212.147 13.706C211.394 12.3199 210.66 10.9709 209.907 9.584L207.547 5.24307C206.714 3.71142 205.821 2.06957 204.83 0.246033Z" fill="#FF7C59"/>
                            <path d="M225.352 0.246033H204.83C205.821 2.06957 206.714 3.71101 207.547 5.24307L209.907 9.584C210.66 10.9702 211.394 12.3199 212.148 13.706C212.901 15.0922 213.675 16.5148 214.508 18.0468L217.224 23.044H241.287L237.794 18.0468L234.76 13.706L231.879 9.584L228.845 5.24307C227.774 3.71142 226.627 2.06957 225.352 0.246033Z" fill="#FFA384"/>
                            <path d="M245.875 0.246033H225.354C226.629 2.06957 227.776 3.71101 228.847 5.24307L231.881 9.584L234.762 13.706L237.796 18.0468L241.289 23.044H265.351L261.082 18.0468L257.374 13.706L253.852 9.584L250.144 5.24307L245.875 0.246033Z" fill="#FF7C59"/>
                            <path d="M24.7127 23.0411H0.649048V36.2142C0.648946 37.8984 0.960108 39.5661 1.56476 41.1221C2.16941 42.6781 3.05571 44.0919 4.17305 45.2828C5.29039 46.4737 6.61688 47.4183 8.07678 48.0628C9.53667 48.7073 11.1014 49.0389 12.6815 49.0388C15.8726 49.0388 18.933 47.6877 21.1895 45.2828C23.4461 42.8779 24.7138 39.6161 24.714 36.2149L24.7127 23.0411Z" fill="#FFA384"/>
                            <path d="M48.7769 23.0411H24.7132V36.2142C24.7132 39.6155 25.9809 42.8775 28.2374 45.2826C30.494 47.6876 33.5545 49.0388 36.7457 49.0388C39.9368 49.0388 42.9972 47.6877 45.2537 45.2828C47.5102 42.8779 48.778 39.6161 48.7782 36.2149L48.7769 23.0411Z" fill="#FFCFBF"/>
                            <path d="M72.8391 23.0411H48.7754V36.2142C48.7754 39.6155 50.0431 42.8775 52.2997 45.2826C54.5562 47.6876 57.6167 49.0388 60.8079 49.0388C63.999 49.0388 67.0594 47.6877 69.3159 45.2828C71.5724 42.8779 72.8402 39.6161 72.8404 36.2149L72.8391 23.0411Z" fill="#FFA384"/>
                            <path d="M96.9036 23.0411H72.8401V36.2142C72.8401 39.6154 74.1077 42.8772 76.364 45.2823C78.6204 47.6873 81.6807 49.0386 84.8718 49.0388C86.4519 49.0389 88.0165 48.7073 89.4764 48.0629C90.9362 47.4184 92.2627 46.4739 93.38 45.283C94.4974 44.0922 95.3837 42.6785 95.9884 41.1226C96.5931 39.5667 96.9043 37.899 96.9043 36.2149L96.9036 23.0411Z" fill="#FFCFBF"/>
                            <path d="M96.9034 23.0411H120.967V36.2142C120.967 39.6155 119.7 42.8775 117.443 45.2826C115.186 47.6876 112.126 49.0388 108.935 49.0388C105.744 49.0388 102.683 47.6876 100.427 45.2826C98.17 42.8775 96.9023 39.6155 96.9023 36.2142V23.0411H96.9034Z" fill="#FFA384"/>
                            <path d="M120.967 23.0411H145.031V36.2142C145.031 39.6155 143.763 42.8775 141.506 45.2826C139.25 47.6876 136.189 49.0388 132.998 49.0388C129.807 49.0388 126.747 47.6877 124.49 45.2828C122.234 42.8779 120.966 39.6161 120.966 36.215V23.0411H120.967Z" fill="#FFCFBF"/>
                            <path d="M169.095 23.0411H145.031V36.2142C145.031 39.6154 146.299 42.8773 148.555 45.2823C150.812 47.6874 153.872 49.0386 157.063 49.0388C160.254 49.0388 163.314 47.6877 165.571 45.2828C167.827 42.8779 169.095 39.6161 169.095 36.2149L169.095 23.0411Z" fill="#FFA384"/>
                            <path d="M193.159 23.0411H169.095V36.2142C169.095 39.6154 170.363 42.8772 172.619 45.2823C174.876 47.6873 177.936 49.0386 181.127 49.0388C184.318 49.0388 187.379 47.6877 189.635 45.2828C191.892 42.8779 193.159 39.6161 193.16 36.2149L193.159 23.0411Z" fill="#FFCFBF"/>
                            <path d="M217.222 23.0411H193.158V36.2142C193.158 39.6154 194.426 42.8773 196.682 45.2823C198.938 47.6874 201.999 49.0386 205.19 49.0388C208.381 49.0388 211.441 47.6877 213.698 45.2828C215.954 42.8779 217.222 39.6161 217.222 36.2149L217.222 23.0411Z" fill="#FFA384"/>
                            <path d="M241.287 23.0411H217.224V36.2142C217.224 39.6154 218.492 42.8773 220.748 45.2823C223.005 47.6874 226.065 49.0386 229.256 49.0388C230.836 49.0389 232.401 48.7073 233.861 48.0629C235.32 47.4184 236.647 46.4739 237.764 45.283C238.882 44.0922 239.768 42.6785 240.373 41.1226C240.977 39.5667 241.288 37.899 241.288 36.2149L241.287 23.0411Z" fill="#FFCFBF"/>
                            <path d="M241.287 23.0411H265.35V36.2142C265.35 39.6155 264.083 42.8775 261.826 45.2826C259.57 47.6876 256.509 49.0388 253.318 49.0388C250.127 49.0388 247.066 47.6876 244.81 45.2826C242.553 42.8775 241.285 39.6155 241.285 36.2142V23.0411H241.287Z" fill="#FFA384"/>
                            <path opacity="0.8" d="M213.252 161.045C213.252 161.045 226.359 159.731 227.798 167.538C229.223 175.346 225.56 178.877 223.615 179.196C221.671 179.515 209.403 178.333 209.59 179.953C209.776 181.573 217.861 184.799 222.603 183.75C222.603 183.75 216.463 195.886 209.816 194.691C203.169 193.496 201.438 189.194 197.322 188.53C193.206 187.866 190.076 188.729 192.061 190.017C194.045 191.305 201.131 191.146 204.235 194.253C207.338 197.36 209.909 199.338 203.582 200.095C197.269 200.852 186.173 199.126 184.335 194.173C183.137 194.744 181.152 197.785 179.753 200.122C179.553 200.52 179.772 200.02 179.306 200.763L178.341 200.56C182.204 194.638 182.497 189.221 182.497 189.221C182.497 189.221 171.095 181.546 177.436 172.956C177.436 172.956 182.364 167.764 187.266 166.728C187.266 166.728 183.576 180.019 186.107 180.55C188.624 181.082 189.13 165.44 189.13 165.44C189.13 165.44 202.237 160.448 207.139 161.338C207.139 161.338 198.214 173.885 200.798 173.779C203.369 173.673 213.252 161.045 213.252 161.045Z" fill="#DDEFFF"/>
                            <path opacity="0.8" d="M36.8201 187.881C32.0636 180.672 43.3537 171.865 43.3537 171.865C43.3537 171.865 60.6433 174.363 62.7493 172.52C64.8553 170.667 48.3876 167.502 48.3876 167.502C51.5722 163.129 65.6258 157.221 65.6258 157.221C65.6258 157.221 77.8302 169.13 79.4123 166.816C80.9943 164.502 68.0708 156.821 68.0708 156.821C72.7039 153.954 80.4909 154.333 80.4909 154.333C91.9557 156.33 88.7916 170.902 88.7916 170.902C88.7916 170.902 93.1063 174.937 100.616 176.688C100.616 176.688 102.105 176.903 104.253 177.681L103.307 178.746C102.948 178.583 102.588 178.429 102.229 178.306C102.229 178.306 102.229 178.306 102.219 178.306C102.208 178.306 102.208 178.306 102.198 178.296C101.972 178.224 101.643 178.122 101.243 177.999C100.318 177.763 99.6502 177.569 99.1879 177.405C96.3218 176.616 85.9768 191.107 80.4396 195.265C74.9024 199.412 75.4263 195.94 75.5188 191.158C75.6112 186.376 81.2922 181.204 81.8778 178.705C82.4634 176.207 79.3609 177.876 76.6283 181.491C73.8956 185.096 75.7859 189.776 71.4712 195.695C67.1565 201.614 53.1645 196.698 53.1645 196.698C57.6847 193.964 61.5885 185.362 60.5098 183.959C59.4414 182.546 50.6887 192.674 48.9218 193.892C47.1342 195.111 41.5765 195.08 36.8201 187.881Z" fill="#DDEFFF"/>
                            <path d="M182.534 115.219C182.534 115.219 192.303 95.3724 197.758 97.1338C203.234 98.8952 182.534 115.219 182.534 115.219Z" fill="#DDEFFF"/>
                            <path d="M175.517 124.199C175.517 124.199 198.909 101.342 208.001 105.51C217.092 109.678 202.463 114.153 196.875 115.372C191.297 116.58 175.517 124.199 175.517 124.199Z" fill="#DDEFFF"/>
                            <path opacity="0.3" d="M55.634 107.245C58.9728 99.8106 71.7422 104.306 71.7422 104.306C71.7422 104.306 78.5224 119.309 81.0701 120.046C83.6179 120.783 77.7828 106.088 77.7828 106.088C82.8988 106.395 94.7539 114.567 94.7539 114.567C94.7539 114.567 91.4357 130.307 94.0861 130.389C96.7366 130.471 96.3154 116.308 96.3154 116.308C100.959 118.531 104.606 124.91 104.606 124.91C108.828 135.028 95.5757 139.913 95.5757 139.913C95.5757 139.913 94.5382 145.392 96.9523 152.243C96.9523 152.243 97.5379 153.533 98.0104 155.632L96.6852 155.428C96.6339 155.059 96.5722 154.69 96.49 154.342C96.49 154.342 96.49 154.342 96.49 154.332V154.322C96.4284 154.107 96.3462 153.789 96.2435 153.41C95.9661 152.55 95.7812 151.925 95.6785 151.475C94.8566 148.802 93.6136 145.279 92.5554 144.419C92.5554 144.419 92.7301 144.235 92.9356 143.856C92.8123 144.04 92.689 144.224 92.5554 144.419C89.5146 148.915 78.019 147.942 71.886 145.648C65.753 143.354 68.8041 142.012 72.6668 139.657C76.5397 137.302 83.5562 139.186 85.8471 138.387C88.138 137.588 85.2307 135.97 80.9571 135.622C76.6835 135.284 73.9098 139.155 66.9858 138.735C60.0617 138.315 56.8873 124.706 56.8873 124.706C61.3664 126.907 70.2218 125.638 70.8073 124.071C71.3929 122.504 58.8598 120.702 56.9798 119.903C55.0895 119.104 52.2953 114.68 55.634 107.245Z" fill="#FFCFBF"/>
                            <path opacity="0.3" d="M210.591 129.29C215.21 133.556 208.79 141.794 208.79 141.794C208.79 141.794 196.153 143.074 194.989 144.757C193.825 146.44 206.005 145.776 206.005 145.776C204.526 149.443 195.641 156.11 195.641 156.11C195.641 156.11 184.941 149.823 184.231 151.742C183.521 153.66 193.984 156.825 193.984 156.825C191.22 159.677 185.659 160.78 185.659 160.78C177.222 161.387 176.899 150.495 176.899 150.495C176.899 150.495 173.146 148.393 167.543 148.479C167.543 148.479 166.455 148.59 164.804 148.421L165.279 147.502C165.561 147.555 165.846 147.6 166.121 147.626C166.121 147.626 166.121 147.626 166.128 147.628L166.136 147.631C166.308 147.638 166.56 147.656 166.863 147.674C167.56 147.682 168.062 147.7 168.417 147.735C170.574 147.789 173.455 147.744 174.344 147.181C174.344 147.181 174.436 147.354 174.663 147.597C174.558 147.462 174.454 147.327 174.344 147.181C171.801 143.854 175.332 135.684 178.514 131.76C181.696 127.837 181.929 130.398 182.705 133.801C183.478 137.211 180.379 141.882 180.401 143.753C180.424 145.625 182.32 143.895 183.623 140.855C184.919 137.811 182.767 134.833 184.772 129.871C186.778 124.909 197.512 125.925 197.512 125.925C194.803 128.662 193.56 135.45 194.562 136.263C195.565 137.076 199.957 128.35 201.002 127.171C202.05 125.984 205.971 125.025 210.591 129.29Z" fill="#FFCFBF"/>
                            <path d="M186.064 71.3374V198.672C186.064 199.904 185.063 200.913 183.816 200.913H85.3821C84.1464 200.913 83.1343 199.916 83.1343 198.672V71.3374C83.1343 70.1056 84.1347 69.0966 85.3821 69.0966H183.816C185.063 69.1084 186.064 70.1056 186.064 71.3374Z" fill="#44A9FE"/>
                            <path d="M93.7606 69.1084V200.913H85.3931C84.1573 200.913 83.1452 199.916 83.1452 198.672V71.3374C83.1452 70.1056 84.1456 69.0966 85.3931 69.0966H93.7606V69.1084Z" fill="#0979FD"/>
                            <path d="M97.6444 66.6003C97.6444 66.6003 95.0082 66.5417 94.9141 69.1109H100.457C100.457 69.1109 101.034 67.1986 102.74 67.8556V66.6003H97.6444Z" fill="#FF7C59"/>
                            <path d="M97.6444 66.6002C97.6444 66.6002 102.34 67.046 102.517 71.0582C102.693 75.0704 102.517 83.0831 102.517 83.0831L106.094 81.7457L108.954 83.0831V70.8822C108.954 70.8822 108.684 67.2337 104.847 66.612H97.6444V66.6002Z" fill="#FFA384"/>
                            <path d="M74.5089 201.754H147.689C152.777 201.754 156.906 197.638 156.906 192.566V192.181C156.906 187.109 152.777 182.993 147.689 182.993H74.5089C69.4205 182.993 65.2913 187.109 65.2913 192.181V192.566C65.2913 197.638 69.4205 201.754 74.5089 201.754Z" fill="#6FC0FE"/>
                            <path d="M115.989 189.871L115.919 193.975C115.861 197.602 118.762 200.587 122.399 200.645L171.038 201.415L171.05 200.599C168.324 199.188 166.476 196.319 166.534 193.043L166.558 191.597C166.604 188.32 168.546 185.521 171.319 184.192V184.18L122.68 183.411C119.042 183.353 116.048 186.244 115.989 189.871Z" fill="#F3F9FF"/>
                            <path d="M172.312 201.753H121.077C117.767 201.753 115.182 197.626 115.182 192.367C115.182 187.108 117.767 182.98 121.077 182.98H172.324C172.745 182.98 173.096 183.318 173.096 183.749C173.096 184.169 172.757 184.519 172.324 184.519H121.077C118.714 184.519 116.726 188.111 116.726 192.367C116.726 196.623 118.714 200.214 121.077 200.214H172.324C172.745 200.214 173.096 200.552 173.096 200.984C173.096 201.415 172.745 201.753 172.312 201.753Z" fill="#A7D8FE"/>
                            <path d="M167.422 187.98H130.422V188.097H167.422V187.98Z" fill="#2391FD"/>
                            <path d="M166.804 189.698H150.076V189.814H166.804V189.698Z" fill="#2391FD"/>
                            <path d="M166.804 195.512H137.619V195.629H166.804V195.512Z" fill="#2391FD"/>
                            <path d="M140.004 188.04C140.004 188.04 141.56 188.693 141.747 190.874C141.934 193.054 141.747 194.22 141.747 194.22L139.875 193.334L137.98 194.22V190.804C137.98 190.804 137.91 188.04 135.325 188.04H140.004Z" fill="#FF8F6D"/>
                            <path d="M60.6127 182.989H118.703C121.545 182.989 123.838 180.692 123.838 177.87C123.838 175.036 121.533 172.751 118.703 172.751H60.6127C57.7702 172.751 55.4775 175.048 55.4775 177.87C55.4775 180.692 57.7702 182.989 60.6127 182.989Z" fill="#FF8F6D"/>
                            <path d="M93.3073 176.497L93.2605 178.736C93.2137 180.718 95.3778 182.339 98.1033 182.374L134.401 182.793L134.412 182.35C132.377 181.581 130.997 180.018 131.044 178.234L131.055 177.453C131.09 175.669 132.541 174.141 134.611 173.418V173.407L98.3138 172.987C95.5883 172.94 93.3541 174.526 93.3073 176.497Z" fill="#FFEFEB"/>
                            <path d="M135.346 182.979H97.0953C94.6271 182.979 92.697 180.729 92.697 177.86C92.697 174.992 94.6271 172.741 97.0953 172.741H135.335C135.65 172.741 135.908 172.928 135.908 173.161C135.908 173.394 135.65 173.581 135.335 173.581H97.0953C95.329 173.581 93.8434 175.54 93.8434 177.86C93.8434 180.181 95.329 182.14 97.0953 182.14H135.335C135.65 182.14 135.908 182.326 135.908 182.559C135.919 182.793 135.662 182.979 135.346 182.979Z" fill="#FF7C59"/>
                            <path d="M131.698 175.461H104.08V175.53H131.698V175.461Z" fill="#FF7C59"/>
                            <path d="M131.23 176.39H118.748V176.46H131.23V176.39Z" fill="#FF7C59"/>
                            <path d="M131.231 179.577H109.45V179.647H131.231V179.577Z" fill="#FF7C59"/>
                            <path d="M188.571 101.162C198.556 97.0511 203.318 85.624 199.207 75.639C195.096 65.6539 183.669 60.8922 173.684 65.0032C163.699 69.1143 158.937 80.5414 163.048 90.5264C167.159 100.511 178.586 105.273 188.571 101.162Z" fill="#FF7C59"/>
                            <path d="M23.8513 101.57C20.8345 101.57 17.8916 102.504 15.4272 104.244C12.9627 105.984 11.0977 108.445 10.0884 111.288C9.07913 114.131 8.97513 117.216 9.79072 120.121C10.6063 123.025 12.3014 125.606 14.6432 127.508L13.7767 133.29L19.021 129.957C21.0266 130.66 23.1615 130.917 25.2765 130.709C27.3916 130.502 29.4359 129.836 31.2668 128.756C33.0976 127.677 34.6709 126.212 35.8768 124.462C37.0827 122.712 37.8921 120.72 38.2486 118.625C38.6051 116.529 38.5001 114.382 37.9408 112.331C37.3816 110.281 36.3816 108.378 35.0107 106.754C33.6398 105.13 31.931 103.825 30.0036 102.929C28.0761 102.034 25.9765 101.57 23.8513 101.57Z" fill="#FF8F6D"/>
                            <path d="M17.1032 117.797C18.2392 117.797 19.1602 116.876 19.1602 115.74C19.1602 114.604 18.2392 113.683 17.1032 113.683C15.9671 113.683 15.0462 114.604 15.0462 115.74C15.0462 116.876 15.9671 117.797 17.1032 117.797Z" fill="white"/>
                            <path d="M23.8539 117.797C24.99 117.797 25.911 116.876 25.911 115.74C25.911 114.604 24.99 113.683 23.8539 113.683C22.7178 113.683 21.7968 114.604 21.7968 115.74C21.7968 116.876 22.7178 117.797 23.8539 117.797Z" fill="white"/>
                            <path d="M30.603 117.797C31.7391 117.797 32.66 116.876 32.66 115.74C32.66 114.604 31.7391 113.683 30.603 113.683C29.467 113.683 28.546 114.604 28.546 115.74C28.546 116.876 29.467 117.797 30.603 117.797Z" fill="white"/>
                            <path d="M255.207 120.691C258.419 113.002 254.791 104.165 247.102 100.952C239.413 97.7394 230.576 101.368 227.363 109.057C224.151 116.746 227.779 125.583 235.468 128.795C243.157 132.008 251.994 128.379 255.207 120.691Z" fill="#2391FD"/>
                            <path d="M241.286 97.4243V114.88H258.742C258.742 110.25 256.903 105.81 253.629 102.537C250.356 99.2634 245.916 97.4243 241.286 97.4243Z" fill="#FFA384"/>
                            <path d="M35.0183 65.52H12.7842C10.5541 65.52 8.74628 67.3279 8.74628 69.558V91.792C8.74628 94.0221 10.5541 95.83 12.7842 95.83H35.0183C37.2484 95.83 39.0563 94.0221 39.0563 91.792V69.558C39.0563 67.3279 37.2484 65.52 35.0183 65.52Z" fill="#44A9FE"/>
                            <path d="M181.128 81.0079C183.832 81.0079 186.066 78.7741 186.066 76.0699C186.066 73.3658 183.832 71.1319 181.128 71.1319C178.424 71.1319 176.19 73.3658 176.19 76.0699C176.307 78.7741 178.424 81.0079 181.128 81.0079Z" fill="white"/>
                            <path d="M181.069 83.4784C177.777 83.4784 171.31 85.1244 171.31 88.4164V90.8854H190.945V88.4164C190.945 85.1244 184.361 83.4784 181.069 83.4784Z" fill="white"/>
                            <path d="M24.5661 82.4583C26.6238 82.9567 27.3267 83.5319 27.3267 84.6821C27.3267 85.8579 26.4193 86.6631 24.5661 86.8165V82.4583ZM23.2881 78.7775C21.4988 78.3046 20.7447 77.64 20.7447 76.6048C20.7447 75.5568 21.6266 74.7133 23.2881 74.5471V78.7775ZM24.5661 79.0714V74.5599C25.4502 74.6666 26.1661 74.996 26.638 75.5454C27.2193 76.2222 27.9063 76.9627 28.7985 76.9627C29.8024 76.9627 30.6412 76.103 30.2715 75.1696C29.4684 73.142 27.4061 71.8656 24.5661 71.6843V70.2529C24.5661 69.8999 24.28 69.6138 23.9271 69.6138C23.5742 69.6138 23.2881 69.8999 23.2881 70.2529V71.6715C19.7223 71.8632 17.1662 73.857 17.1662 76.9371C17.1662 79.6977 18.9938 81.193 22.3423 81.9471L23.2881 82.1643V86.8165C22.216 86.7176 21.447 86.3565 20.9542 85.8002C20.3637 85.1338 19.6692 84.4137 18.7789 84.4137C17.7556 84.4137 16.8942 85.2879 17.2816 86.235C18.1394 88.3321 20.4005 89.5502 23.2881 89.6921V91.098C23.2881 91.4509 23.5742 91.737 23.9271 91.737C24.28 91.737 24.5661 91.4509 24.5661 91.098V89.6793C28.2469 89.462 30.8925 87.5194 30.8925 84.2859C30.8925 81.487 29.0649 80.0811 25.563 79.2887L24.5661 79.0714Z" fill="white"/>
                            <path d="M248.285 65.2742H231.946C229.752 65.2742 227.974 67.0524 227.974 69.246V85.5853C227.974 87.7789 229.752 89.5571 231.946 89.5571H248.285C250.478 89.5571 252.257 87.7789 252.257 85.5853V69.246C252.257 67.0524 250.478 65.2742 248.285 65.2742Z" fill="#FFDBD0"/>
                            <path d="M234.032 76.446L238.51 82.7953L251.442 65.7444" stroke="#FF7C59" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg class="line" width="230" height="1" viewBox="0 0 230 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="230" height="1" fill="#DDEFFF"/>
                            </svg>
                            <span class="readmore">
                                Xem thêm
                            </span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12" style="padding: 0px; margin-bottom: 50px;">
            <div class="sum-grid">
                <div class="col-12">
                    <div class="section" style="width: 100%">
                        <div class="title">Danh sách hàng hủy</div>
                        <div class="grid-default">
                            <div class="list_grid">
                                <app-list-grid-angular
                                    #aggrid1
                                    [height]="heightGrid"
                                    [columnDefs]="gridColumn.goodCancelCol" [listsData]="gridData.goodCancelData" [hideSidebar]="true"></app-list-grid-angular>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="section" style="width: 100%">
                        <div class="title">Danh sách sản phẩm không bán được</div>
                        <div class="grid-default">
                            <div class="list_grid">
                                <app-list-grid-angular
                                    #aggrid3
                                    [height]="heightGrid"
                                    [columnDefs]="gridColumn.slowSellingCol" [listsData]="gridData.slowSellingData" [hideSidebar]="true"></app-list-grid-angular>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="section" style="width: 100%">
                        <div class="title">Danh sách sản phẩm tồn kho dưới mức tối thiểu (Thời điểm hiện tại)</div>
                        <div class="grid-default">
                            <div class="list_grid">
                                <app-list-grid-angular
                                    #aggrid2
                                    [height]="heightGrid"
                                    [columnDefs]="gridColumn.productBelowMinCol" [listsData]="gridData.productBelowMinData" [hideSidebar]="true"></app-list-grid-angular>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="section" style="width: 100%">
                        <div class="title">Tồn kho (Thời điểm hiện tại)</div>
                        <div class="grid-default">
                            <div class="list_grid">
                                <app-list-grid-angular
                                    #aggrid4
                                    [height]="heightGrid"
                                    [columnDefs]="gridColumn.currentStockCol" [listsData]="gridData.currentStockData" [hideSidebar]="true"></app-list-grid-angular>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="home-notice" *ngIf="isShowNotice">
    <div class="number-day-use">
        <label>{{expired_date}}</label>
        <span class="btn btn-default">Kích hoạt gói dịch vụ ngay</span>
    </div>
    <div class="contact">
        <span class="btn">
            <i class="pi pi-phone"></i>
            1900435467
        </span>
        <span class="btn">
            <i class="pi pi-envelope"></i>
            Email
        </span>
    </div>
    <span class="delete-section" (click)="closeNotice()">
        <span class="pi pi-times-circle"></span>
    </span>
</div>