import { LoginModel } from './../models/login.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AUTH_CONSTANT } from '../common/constants/auth.constanst';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient) {
    }

    login(params: LoginModel): Observable<any> {
        const body = {
            ...params,
            "client_id": "swagger_development",
            "grant_type": "password",
            "scope": "openid"
        };
        return this.http.post(environment.BASE_URL + '/api/v1/auth/Login', body);
    }

    refreshToken(refreshToken: string): Observable<any> {
        const body = {
            "refresh_token": refreshToken,
            "client_id": "swagger_development",
            "grant_type": "password",
            "scope": "openid"
        };
        return this.http.post(`${environment.BASE_URL}/api/v1/auth/RefreshToken`, body);
    }


    getUserInfo(username, authorization): Observable<any> {
        return this.http.get(`${environment.BASE_URL}/api/v1/user/GetByUserName?username=${username}`, {
            headers: new HttpHeaders({
                Authorization: `Bearer ${authorization}`,
                'Content-Type': 'application/json',
            })
        });
    }

    setUserInfo(data) {
        localStorage.setItem(AUTH_CONSTANT.AVATAR_URL, data.data.avatar || '');
        localStorage.setItem(AUTH_CONSTANT.NAME, data.data.name || '');
        localStorage.setItem(AUTH_CONSTANT.USER_ID, data.data.id || '');
        localStorage.setItem(AUTH_CONSTANT.USER_NAME, data.data.user_name || '');
    }

    getUserName() {
        return localStorage.getItem(AUTH_CONSTANT.USER_NAME);
    }

    getName() {
        return localStorage.getItem(AUTH_CONSTANT.NAME);
    }

    getUserId() {
        return localStorage.getItem(AUTH_CONSTANT.USER_ID);
    }

    loginSuccess(data): void {
        localStorage.setItem(AUTH_CONSTANT.ID_TOKEN, data.id_token || '');
        localStorage.setItem(AUTH_CONSTANT.EXPIRES_IN, `${data.expires_in}` || '');
        localStorage.setItem(AUTH_CONSTANT.REFRESH_TOKEN, data.refresh_token || '');
        localStorage.setItem(AUTH_CONSTANT.REFRESH_TOKEN_BY_IN, data.refresh_expires_in || '');
        localStorage.setItem(AUTH_CONSTANT.SCOPE, data.scope || '');
        localStorage.setItem(AUTH_CONSTANT.SESSION_STATE, data.session_state || '');
        localStorage.setItem(AUTH_CONSTANT.TOKEN, data.access_token || '');
        localStorage.setItem(AUTH_CONSTANT.NAME, data.name);
        localStorage.setItem(AUTH_CONSTANT.USER_NAME, data.username || '');
    }

    getClaims() {
        // let decode = JSON.parse(window.atob(this.getToken().split('.')[1]));
        let decode = jwt_decode(this.getToken());

        if (decode == undefined) {
            return "";
        }
        return decode;
    }

    logout(): void {
        localStorage.removeItem(AUTH_CONSTANT.ID_TOKEN);
        localStorage.removeItem(AUTH_CONSTANT.EXPIRES_IN);
        localStorage.removeItem(AUTH_CONSTANT.REFRESH_TOKEN);
        localStorage.removeItem(AUTH_CONSTANT.REFRESH_TOKEN_BY_IN);
        localStorage.removeItem(AUTH_CONSTANT.SCOPE);
        localStorage.removeItem(AUTH_CONSTANT.SESSION_STATE);
        localStorage.removeItem(AUTH_CONSTANT.TOKEN);
        localStorage.removeItem(AUTH_CONSTANT.AVATAR_URL);
        localStorage.removeItem(AUTH_CONSTANT.NAME);
        localStorage.removeItem(AUTH_CONSTANT.USER_ID);
        localStorage.removeItem(AUTH_CONSTANT.USER_NAME);
        localStorage.removeItem(AUTH_CONSTANT.MENU_ITEMS);
    }

    expireToken(): boolean {
        const token = localStorage.getItem(AUTH_CONSTANT.TOKEN);
        if (token) {
            const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
            return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
        }
        return true;
    }

    getToken(): string {
        return localStorage.getItem(AUTH_CONSTANT.TOKEN);
    }

    getRefreshToken(): string {
        return localStorage.getItem(AUTH_CONSTANT.REFRESH_TOKEN);
    }

    getExpireIn(): string {
        return localStorage.getItem(AUTH_CONSTANT.EXPIRES_IN);
    }

    getIdToken(): string {
        return localStorage.getItem(AUTH_CONSTANT.ID_TOKEN);
    }

    getSessionState(): string {
        return localStorage.getItem(AUTH_CONSTANT.SESSION_STATE);
    }
}
