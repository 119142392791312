
<div class="app-body" >
  <div class="header">
    <app-navbar></app-navbar>
  </div>
  <div class="main">
    <app-sidebar-customer ></app-sidebar-customer>
    <router-outlet>
    </router-outlet>
  </div>
</div>

