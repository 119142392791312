<div class="account-form login">
    <div class="">
        <div class="text-center mb50">
            <img src="../../../assets/images/logo.png">
        </div>
        <div class="mw750 mg-auto box-register">
            <p-tabView>
              
                <p-tabPanel header="Đăng ký bằng số điện thoại">
                    <div class="wrap-form">
                        <form [formGroup]="createDetailForm">
                            <div class="grid">
                                <div class="group col-6">
                                    <label for="username">Họ và tên <span style="color: red">*</span></label>
                                    <input id="" type="text" formControlName="name" required
                                        placeholder="Họ tên của bạn">
                                </div>
                                <div class="group col-6">
                                    <label for="">Tỉnh thành phố</label>
                                    <p-dropdown [options]="listCities" [autoDisplayFirst]="false" [appendTo]="'body'"
                                        [filter]="true" formControlName="city_id">
                                        <ng-template let-item pTemplate="selectedItem">
                                            <span style="vertical-align:middle;">{{item.label}}</span>
                                        </ng-template>
                                        <ng-template let-car pTemplate="item">
                                            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                                <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div class="group col-6">
                                    <label for="">Tên cửa hàng <span style="color: red">*</span></label>
                                    <input placeholder="Nhập tên cửa hàng" formControlName="store_name">
                                </div>
                                <div class="group col-6">
                                    <label for="">Gói đăng ký <span style="color: red">*</span></label>
                                    <p-dropdown [options]="listPackages" [autoDisplayFirst]="false" [appendTo]="'body'"
                                        formControlName="package_id" >
                                        <ng-template let-item pTemplate="selectedItem">
                                            <span style="vertical-align:middle;">{{item.label}}</span>
                                        </ng-template>
                                        <ng-template let-car pTemplate="item">
                                            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                                <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div class="group col-6">
                                    <div class="group-fields">
                                        <label for="">Số điện thoại <span style="color: red">*</span></label>
                                        <div class="group-phone">
                                            <div class="group geo">
                                                <p-dropdown placeholder="+84" [options]="geos"
                                                    [autoDisplayFirst]="false" [appendTo]="'body'">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span style="vertical-align:middle;">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;float:right;margin-top:4px">
                                                                {{car.label}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="group">
                                                <div class="sdt d-flex">
                                                    <!-- <span class="line-ho"></span> -->
                                                    <input type="number" placeholder="Số điện thoại của bạn" formControlName="phone">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="group col-6">
                                    <label for="">Email <span style="color: red">*</span></label>
                                    <input placeholder="Nhập email" formControlName="email" name="email">
                                </div> -->
                                <div class="col-6">
                                    <div class="grid">
                                        <div class="col-6 ptb0">
                                            <div class="group">
                                                <label class="empty-space" for="">&nbsp;</label>
                                                <!-- class 'sent': đã gửi, remove sent: chưa gửi -->
                                                <button class="btn-input sent" [disabled]="isSent" (click)="sendOTP()">Gửi <span
                                                        *ngIf="isSent">lại</span> mã
                                                    xác thực <span *ngIf="isSent" class="time" style="color: red;">({{
                                                        counter }}
                                                        s)</span></button>
                                            </div>
                                        </div>
                                        <div class="col-6 ptb0">
                                            <div class="group mxt">
                                                <label for="">Mã xác thực <span style="color: red">*</span></label>
                                                <input placeholder="Nhập mã xác thực" formControlName="otp">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-6 group">
                                    <label for="">Tên đăng nhập <span style="color: red">*</span></label>
                                    <input placeholder="Nhập tên đăng nhập" formControlName="username" name="username">
                                </div> -->
                            </div>
                            <div class="con fields">
                                <p-checkbox [binary]="true" name="isUseTemplate" [(ngModel)]="isUseTemplate"
                                    [ngModelOptions]="{standalone: true}"></p-checkbox>
                                Tôi đồng ý với<a>quy định sử dụng</a>và<a>chính sách bảo mật</a>của Usee
                            </div>
                            <div class="actions mw340 mg-auto">
                                <button class="act login btn" (click)="saveRegister()">Đăng ký </button>
                            </div>
                            <span class="line mw100"></span>
                            <p class="text-center text-login-with">Hoặc đăng nhập với</p>
                            <div class="d-flex gap30 center">
                                <div>
                                    <img src="../../../assets/images/account/login-google.png">
                                </div>
                                <div>
                                    <img src="../../../assets/images/account/login-facebook.png">
                                </div>
                            </div>
                            <!-- <div class="group">
                                <label for="">Tên cửa hàng <span style="color: red">*</span></label>
                                <input placeholder="Nhập tên cửa hàng" formControlName="store_name" name="store_name">
                            </div>
                            <div class="grid">
                                <div class="col-12">
                                    <div class="group">
                                        <label for="">Tên đăng nhập <span style="color: red">*</span></label>
                                        <input placeholder="Nhập tên đăng nhập" formControlName="username"
                                            name="username">
                                    </div>
                                </div>
                            </div>
                            <div class="group">
                                <label for="">Gói đăng ký <span style="color: red">*</span></label>
                                <p-dropdown [options]="listPackages" [autoDisplayFirst]="false" [appendTo]="'body'"
                                    formControlName="package_id" name="package_id">
                                    <ng-template let-item pTemplate="selectedItem">
                                        <span style="vertical-align:middle;">{{item.label}}</span>
                                    </ng-template>
                                    <ng-template let-car pTemplate="item">
                                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                            <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="grid">
                                <div class="col-12">
                                    <div class="group">
                                        <label for="">Email <span style="color: red">*</span></label>
                                        <input placeholder="Nhập email" formControlName="email" name="email">
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="grid">
                                <div class="col-6">
                                    <div class="group">
                                        <label for="">&nbsp;</label>
                                        class 'sent': đã gửi, remove sent: chưa gửi
                                        <button class="btn-input sent" (click)="sendOTP()">Gửi <span
                                                *ngIf="isSent">lại</span>
                                            mã
                                            xác thực <span *ngIf="isSent" class="time" style="color: red;">({{ counter
                                                }}
                                                s)</span></button>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="group mxt">
                                        <label for="">Mã xác thực <span style="color: red">*</span></label>
                                        <input placeholder="Nhập mã xác thực" formControlName="otp" name="otp">

                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="con">
                                <p-checkbox name="groupname" value="val1" label="Value 1" [(ngModel)]="isUseTemplate" [ngModelOptions]="{standalone: true}"></p-checkbox>
                                <p-checkbox [binary]="true" name="isUseTemplate" [(ngModel)]="isUseTemplate"
                                    [ngModelOptions]="{standalone: true}"></p-checkbox>
                                Sử dụng dữ liệu mẫu do Usee cung cấp
                            </div>
                            <div class="actions">
                                <button class="act login btn" (click)="saveRegister()">Đăng ký </button>
                            </div>
                            <p class="text-center text-login-with">Hoặc đăng nhập với</p>
                            <div class="login-width">
                                <button class="btn btn-facebook" (click)="saveRegister()">Facebook</button>
                                <div id="googleLogin"></div>
                            </div>
                            <span class="line">
                            </span> -->
                        </form>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Đăng ký bằng email">
                    <div class="wrap-form">
                        <form [formGroup]="createDetailForm">
                            <div class="grid">
                                <div class="group col-6">
                                    <label for="username">Họ và tên <span style="color: red">*</span></label>
                                    <input id="" type="text"formControlName="name" required
                                        placeholder="Họ tên của bạn">
                                </div>
                                <div class="group col-6">
                                    <label for="">Tỉnh thành phố</label>
                                    <p-dropdown [options]="listCities" [autoDisplayFirst]="false" [appendTo]="'body'"
                                        [filter]="true" formControlName="city_id">
                                        <ng-template let-item pTemplate="selectedItem">
                                            <span style="vertical-align:middle;">{{item.label}}</span>
                                        </ng-template>
                                        <ng-template let-car pTemplate="item">
                                            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                                <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div class="group col-6">
                                    <label for="">Tên cửa hàng <span style="color: red">*</span></label>
                                    <input placeholder="Nhập tên cửa hàng" formControlName="store_name">
                                </div>
                                <div class="group col-6">
                                    <label for="">Gói đăng ký <span style="color: red">*</span></label>
                                    <p-dropdown [options]="listPackages" [autoDisplayFirst]="false" [appendTo]="'body'"
                                        formControlName="package_id">
                                        <ng-template let-item pTemplate="selectedItem">
                                            <span style="vertical-align:middle;">{{item.label}}</span>
                                        </ng-template>
                                        <ng-template let-car pTemplate="item">
                                            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                                <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <!-- <div class="group col-6">
                                    <label for="">Số điện thoại <span style="color: red">*</span></label>
                                    <div class="sdt d-flex">
                                        <span class="line-ho"></span>
                                        <input (keyup)="onChangePhone()" type="number" placeholder="Số điện thoại của bạn" formControlName="phone" name="phone">
                                    </div>
                                </div> -->
                                <div class="group col-6">
                                    <label for="">Email <span style="color: red">*</span></label>
                                    <input placeholder="Nhập email" formControlName="email">
                                </div>
                                <div class="col-6">
                                    <div class="grid">
                                        <div class="col-6 ptb0">
                                            <div class="group">
                                                <label class="empty-space" for="">&nbsp;</label>
                                                <!-- class 'sent': đã gửi, remove sent: chưa gửi -->
                                                <button class="btn-input sent" [disabled]="isSent" (click)="sendOTPEmail()">Gửi <span
                                                        *ngIf="isSent">lại</span> mã
                                                    xác thực <span *ngIf="isSent" class="time" style="color: red;">({{
                                                        counter }}
                                                        s)</span></button>
                                            </div>
                                        </div>
                                        <div class="col-6 ptb0">
                                            <div class="group mxt">
                                                <label for="">Mã xác thực <span style="color: red">*</span></label>
                                                <input placeholder="Nhập mã xác thực" formControlName="otp">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-6 group">
                                    <label for="">Tên đăng nhập <span style="color: red">*</span></label>
                                    <input placeholder="Nhập tên đăng nhập" formControlName="username" name="username">
                                </div> -->
                            </div>

                            <div class="con fields">
                                <!-- <p-checkbox name="groupname" value="val1" label="Value 1" [(ngModel)]="isUseTemplate" [ngModelOptions]="{standalone: true}"></p-checkbox> -->
                                <p-checkbox [binary]="true" name="isUseTemplate" [(ngModel)]="isUseTemplate"
                                    [ngModelOptions]="{standalone: true}"></p-checkbox>
                                Tôi đồng ý với<a>quy định sử dụng</a>và<a>chính sách bảo mật</a>của Usee
                            </div>
                            <div class="actions mw340 mg-auto">
                                <button class="act login btn" (click)="saveRegisterEmail()">Đăng ký </button>
                            </div>
                            <span class="line mw100"></span>
                            <p class="text-center text-login-with">Hoặc đăng nhập với</p>
                            <div class="d-flex gap30 center">
                                <div>
                                    <img src="../../../assets/images/account/login-google.png">
                                </div>
                                <div>
                                    <img src="../../../assets/images/account/login-facebook.png">
                                </div>
                            </div>
                        </form>

                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
       
    </div>
    <p-dialog header="Đăng ký thành công" [(visible)]="displayMessageSuccess" [modal]="true" [style]="{width: '30vw'}"  [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [closable]="false">
    <p>{{messageSuccess}}</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="changeDisplayMessageSuccess()" label="Đóng" class="p-button-text"></p-button>
        </ng-template>
</p-dialog>